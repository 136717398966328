// ===============================================
// SERVICE
// ===============================================


.service {
	overflow: hidden;
	padding-top: 25px;
	padding-bottom: 35px;

	@media (min-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@media (min-width: 1440px) {
		padding-top: 75px;
	}

	&--light {
		background-color: #ffffff;
	}

	&:first-child {
		@media (min-width: 768px) {
			padding-top: 0;
		}

		@media (min-width: 1201px) {
			padding-top: 20px;
		}
	}

	&:last-child {
		padding-bottom: 80px;

		@media (min-width: 768px) {
			padding-bottom: 110px;
		}
	}
}

.service__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 768px) {
		max-width: 1710px;
		padding: 0 40px;
	}
}

.service__title {
	margin-bottom: 20px;

	@media (min-width: 768px) {
		margin-bottom: 30px;
	}

	@media (min-width: 1440px) {
		margin-bottom: 50px;
	}
}

.service__content {
	@media (min-width: 1201px) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}

.service__text {
	@media (min-width: 1201px) {
		max-width: 800px;
		width: 52%;
	}
}

.service__right {
	max-width: 720px;

	@media (min-width: 1201px) {
		width: 46%;
	}

	@media (max-width: 1200px) {
		margin: 40px auto 0 auto;
	}
}

.service__slider {
	width: 100%;
}

.service__block {
	position: relative;
	color: #ffffff;
	margin-top: 40px;

	@media (min-width: 768px) {
		padding: 30px 60px 45px 65px;
		z-index: 1;
	}

	@media (min-width: 1201px) {
		margin: 60px 10px 0 10px;
	}

	@media (min-width: 1440px) {
		margin-top: 120px;
	}

	@media (max-width: 767px) {
		background-color: #004990;
		padding: 30px;
	}

	&::before {
		content: '';
		@include style-cover;
		transform: skew(-10deg);

		@media (min-width: 768px) {
			background-color: #004990;

			border-right: 105px solid rgba(91, 197, 242, .07);
			z-index: -1;
		}

		@media (max-width: 767px) {
			left: auto;
			right: -100px;
			width: 230px;
			background-color: rgba(91, 197, 242, .07);
		}
	}
}

.service__block-title {
	color: #ffffff;
}
