// ===============================================
// NEWS
// ===============================================


.news {
	position: relative;
	background-color: #e8f3f8;
	padding-top: 35px;
	padding-bottom: 35px;
	overflow: hidden;
	z-index: 1;

	@media (min-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-top: 85px;
		padding-bottom: 100px;
	}

	@media (min-width: 1440px) {
		padding-top: 105px;
		padding-bottom: 120px;
	}
}

.news__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1600px;
		padding: 0 40px;
	}
}

.news__headline {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.news__title {

}

.news__btns {

}

.news__slider {
	margin-top: 30px;

	@media (min-width: 1280px) {
		margin-top: 40px;
	}

	@media (min-width: 992px) and (max-width: 1600px) {
		padding-left: 40px;
		margin-left: -40px;
		margin-right: -40px;
	}

	@media (max-width: 991px) {
		padding-left: 20px;
		margin: 25px -20px 0 -20px;
	}
}

.news__item {
	position: relative;
	width: 290px;
	background-color: #ffffff;
	overflow: hidden;
	padding: 20px 15px;

	@media (min-width: 768px) {
		width: 355px;
		padding: 30px 25px;
	}

	@media (hover: hover) {
		&:hover,
		&:focus-within {
			.news__item-img {
				&::after {
					opacity: 1;
				}
			}

			.news__item-title {
				color: #1d2429;
			}

						.news__item-link {
				&::before {
					transform: skew(-10deg) translate(-95%);
				}
			}
		}
	}
}

.news__item-img {
	position: relative;
	width: 100%;
	height: 130px;

	@media (min-width: 768px) {
		height: 155px;
	}

	&::after {
		content: '';
		@include style-cover;
		background-color: rgba(0,91,170, .55);
		opacity: 0;
		transition: opacity .4s;
	}

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.news__item-text {
	min-height: 200px;
	display: flex;
	flex-direction: column;
	font-size: 13px;
	line-height: 14px;
	padding: 25px 10px 0 10px;

	@media (min-width: 768px) {
		min-height: 270px;
		padding: 50px 15px 20px 30px;
	}
}

.news__item-title {
	min-height: 54px;
	font-size: 16px;
	line-height: 18px;
	color: #0999fc;
	transition: color .4s;
	margin-bottom: 15px;
}

.news__item-date {
	display: inline-block;
	font-size: 13px;
	line-height: 18px;
	color: #848b92;
	padding-top: 15px;
	margin-top: auto;
}

.news__item-link {
	@include style-cover;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 115%;
		width: 65%;
		bottom: 0;
		background-color: rgba(91, 197, 242, .12);
		transform: skew(-10deg);
		transition: transform .4s;
	}
}
