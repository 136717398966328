// ===============================================
// DOCUMENTS
// ===============================================


.documents {
	position: relative;
	background-color: #f2f5f9;
	overflow: hidden;
	padding-top: 35px;
	padding-bottom: 80px;
	z-index: 1;

	@media (min-width: 768px) {
		padding-top: 50px;
	}

	@media (min-width: 992px) {
		padding-top: 80px;
		padding-bottom: 100px;
	}

	@media (min-width: 1501px) {
		padding-top: 120px;
		padding-bottom: 110px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 30px;
		width: 230px;
		background-color: rgb(91, 197, 242, .11);
		transform: skew(-10deg);
		z-index: -1;
	}
}

.documents__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1740px;
		padding: 0 40px;
	}
}

.documents__text {
	font-size: 15px;
}

.documents__title {
	margin-bottom: 5px;
}

.documents__list {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
	grid-gap: 50px 20px;
	list-style: none;
	padding: 0;
	padding-top: 30px;
	margin: 0;

	@media (min-width: 768px) {
		grid-gap: 60px 30px;
		padding-top: 40px;
	}
}

.documents__item {
	justify-self: center;
}
