// ===============================================
// MODALS
// ===============================================


.modals {
	@include style-cover-fixed;
	z-index: 200;
	overflow-y: auto;
	display: none;

	&-scroll {
		overflow: hidden;
	}
}

.modals__overlay {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	min-height: 100%;
	background-color: rgba(0,13,25, .82);
	background-color: rgba(175, 192, 209, .58);
	overflow: hidden;
	padding: 40px 20px;

	@media (max-width: 374px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.modal {
	display: none;
	position: relative;
	color: #0c2844;
	max-width: 660px;
	background-color: #ffffff;
	padding: 10px 10px 25px 10px;
	box-shadow: 0 70px 80px -55px rgba(14,51,90, .4);
	z-index: 1;

	&--thanks {
		max-width: 700px;
		color: #ffffff;
		background-color: transparent;
		text-align: center;
		padding: 50px 20px 30px 20px;
		box-shadow: none;

		&::before {
			content: '';
			@include style-cover;
			background-color: #004990;
			transform: skew(-10deg);
			z-index: -1;
		}

		@media (min-width: 601px) {
			padding: 90px 50px 60px 50px;
		}

		.modal__close {
			top: 15px;
			right: 0;
		}
	}
}

.modal__fon {
	@include style-cover;
	background-color: #004990;
	transform: skew(-10deg);
	overflow: hidden;
	z-index: -1;

	&::before {
		content: '';
		position: absolute;
		top: -110px;
		left: -235px;
		width: 409px;
		height: 406px;
		background-image: url('/img/logo-star-light.svg');
		@include background-img;
		transform-origin: center;
		transition: transform .4s;
		opacity: .05;
		pointer-events: none;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100px;
		background-color: rgba(91, 197, 242, .07);
		z-index: -1;
	}
}

.modal__close {
	position: absolute;
	top: 15px;
	right: 15px;
	background-color: #005baa;

	@media (min-width: 768px) {
		top: -45px;
		right: -55px;
	}
}

.modal__title {
	color: #ffffff;
	margin-bottom: 10px;
}

.modal__button {
	margin-top: 30px;

	@media (min-width: 601px) {
		margin-top: 45px;
	}
}


.modal__text {
	font-size: 12px;
	line-height: 1.2;
	color: #0c2844;

	img {
		display: block;
		max-width: 100%;
		height: auto;
		margin: 0 auto 10px auto;
	}

	h4 {
		font-size: 14px;
		line-height: 1.2;
		font-weight: 500;
		color: #1d2429;
		margin-bottom: 2px;
	}
}
