// ===============================================
// HEADING
// ===============================================


// Requires inline-block or block for proper styling

@mixin style-text-h1() {
	font-size: 28px;
	line-height: 30px;
	font-weight: 500;
	color: #1d2429;
	text-transform: uppercase;
	margin: 0;

	@media (min-width: 481px) {
		font-size: 38px;
		line-height: 1;
	}

	@media (min-width: 768px) {
		font-size: 45px;
	}

	@media (min-width: 992px) {
		font-size: 52px;
	}

	@media (min-width: 1440px) {
		font-size: 60px;
	}
}

@mixin style-text-h2() {
	font-size: 24px;
	line-height: 1;
	font-weight: 500;
	color: #1d2429;
	margin: 0;

	@media (min-width: 768px) {
		font-size: 30px;
	}

	@media (min-width: 992px) {
		font-size: 36px;
		line-height: 43px;
	}
}

@mixin style-text-h3() {
	font-size: 20px;
	line-height: 22px;
	font-weight: 500;
	color: #1d2429;
	margin: 0;

	@media (min-width: 768px) {
		font-size: 24px;
		line-height: 30px;
	}
}

@mixin style-text-h4() {
	font-size: 16px;
	line-height: 18px;
	font-weight: 500;
	color: #1d2429;
	margin: 0;

	@media (min-width: 992px) {
		font-size: 18px;
		line-height: 21px;
	}
}
