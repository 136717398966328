// ===============================================
// VIDEO
// ===============================================


.video {
	position: relative;
	color: #ffffff;
	background: linear-gradient(to right, #004990 30%, #0079d3),
			#004990;


	@media (min-width: 992px) {
		display: flex;
		height: 300px;
		background: linear-gradient(to right, #00386e 20%, #005cae),
				#00386e;
	}

	@media (min-width: 1440px) {
		height: 370px;
	}
}

.video__link {
	display: block;
	position: relative;
	height: 100%;

	@media (min-width: 992px) {
		width: 54%;
		margin-left: -35px;
		overflow: hidden;
		transform: skew(-10deg);
	}

	@media (min-width: 1201px) {
		width: 60%;
	}

	@media (max-width: 991px) {
		height: 280px;
		width: 100%;
	}

	@media (max-width: 767px) {
		height: 200px;
	}
}

.video__media {
	width: 100%;
	height: 100%;
	object-fit: cover;

	@media (min-width: 992px) {
		margin-left: 35px;
		transform: skew(10deg);
	}
}

.video__description {
	padding: 20px 20px 35px 20px;

	@media (min-width: 992px) {
		position: relative;
		width: 46%;
		padding: 70px 40px 0 65px;
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 225px;
			background-color: rgba(91, 197, 242, .07);
			transform: skew(-10deg);
			pointer-events: none;
			z-index: -1;
		}
	}

	@media (min-width: 1201px) {
		width: 40%;
	}
}

.video__text {
	max-width: 345px;

	@media (max-width: 991px) {
		text-align: center;
		margin: 0 auto;
	}
}

.video__title {
	font-size: 15px;
	line-height: 30px;
	color: #ffffff;
}

.video__information {
	position: relative;
	display: inline-flex;
	align-items: center;
	color: #ffffff;
	text-decoration: none;
	margin-top: 20px;

	@media (min-width: 992px) {
		margin-left: -115px;
		padding-left: 150px;
		margin-top: 50px;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 84px;
			height: 84px;
			background-color: #ffffff;
			background-image: url('/img/icons/play.png');
			background-position: left 35px center;
			background-repeat: no-repeat;
			border-radius: 50%;
			transform: translateY(-50%);
			box-shadow: 5px 18px 25px 0 rgba(0, 73, 144, .8), 0 0 0 16px rgba(255, 255, 255, .33), -10px -10px 15px -10px rgba(91, 197, 242, .2);
			z-index: 1;
		}
	}

	@media (min-width: 1440px) {
		margin-top: 90px;
	}


	@media (hover: hover) {
		&:hover,
		&:focus {
			&::before {
				animation: pulse 1.5s infinite;
			}
		}
	}
}

.video__span {
	font-family: 'Helvetica-6';
	text-transform: uppercase;
	margin-right: 20px;
}

.video__duration {
	font-size: 12px;
	color: #5bb5ff;
}
