// ===============================================
// O-EQUIPMENT
// ===============================================


.o-equipment {
	overflow: hidden;
	padding-bottom: 35px;

	@media (max-width: 767px) {
		padding-top: 25px;
	}

	@media (min-width: 768px) {
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-bottom: 100px;
	}
}

.o-equipment__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1710px;
		padding: 0 40px;
	}
}

.o-equipment__content {
	margin-top: 20px;

	@media (min-width: 992px) {
		font-size: 16px;

		p + p {
			margin-top: 20px;
		}
	}

	@media (min-width: 1201px) {
		display: flex;
		align-items: flex-start;
		margin-top: 30px;
	}
}

.o-equipment__media {
	@media (min-width: 1201px) and (max-width: 1730px) {
		width: 50%;
	}

	@media (min-width: 1201px) {
		margin-right: 30px;
	}

	@media (min-width: 1731px) {
		max-width: 865px;
		margin-right: 55px;
	}
}

.o-equipment__slider {
	position: relative;

	@media (max-width: 600px) {
		margin-left: -15px;
		margin-right: -15px;
	}

	@media (min-width: 601px) and (max-width: 767px) {
		margin-left: -15px;
	}

	@media (min-width: 768px) and (max-width: 991px) {
		margin-left: -20px;
	}

	@media (min-width: 992px) and (max-width: 1730px) {
		margin-left: -40px;
	}

	@media (min-width: 1201px) {
		width: 1000px;
	}

	@media (min-width: 1201px) and (max-width: 1730px) {
		max-width: 1000px;
		width: calc(100% + 55px);
	}

	@media (min-width: 1731px) and (max-width: 1850px) {
		margin-left: -100px;
	}

	@media (min-width: 1851px) {
		margin-left: -135px;
	}

	.swiper {
		width: 100%;

		@media (min-width: 601px) {
			transform: skew(-10deg);
			margin-left: -55px;
		}

		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 15%;
			max-width: 145px;
			bottom: 0;
			background-color: rgba(91, 197, 242, .08);
			pointer-events: none;
			z-index: 1;
		}
	}
}

.o-equipment__slider-button {
	position: absolute;
	top: 50%;
	right: 25px;
	transform: translateY(-50%) skew(-10deg);
	z-index: 2;

	@media (max-width: 600px) {
		display: none;
	}
}

.o-equipment__slider-img {
	display: block;
	width: 100%;
	height: auto;

	@media (min-width: 601px) {
		transform: skew(10deg) translateX(55px);
	}
}

.o-equipment__description {
	margin-top: 30px;

	@media (min-width: 1201px) {
		max-width: 680px;
		margin-top: 95px;
	}
}

.o-equipment__text {
	@media (min-width: 1201px) {
		max-width: 635px;
		width: 100%;
	}

	@media (min-width: 1731px) {
		padding-top: 100px;
	}

	@media (max-width: 1200px) {
		margin-top: 15px;
	}
}

.o-equipment__title {
	margin-bottom: 20px;

	@media (min-width: 992px) {
		margin-bottom: 30px;
	}

	@media (min-width: 1201px) {
		margin-bottom: 55px;
	}

	@media (min-width: 1440px) {
		line-height: 72px;
	}

	@media (max-width: 1200px) {
		display: none;
	}

	&--mobile {
		display: block;

		@media (min-width: 1201px) {
			display: none;
		}
	}
}

.o-equipment__presentation {
	margin-top: 30px;

	@media (min-width: 1731px) {
		margin-top: 150px;
	}
}
