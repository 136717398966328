// ===============================================
// EQUIPMENTS
// ===============================================


.equipments {
	overflow: hidden;

	@media (max-width: 767px) {
		padding-top: 25px;
	}
}

.equipments__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1830px;
		padding: 0 40px;
	}
}

.equipments__title {
	margin-bottom: 30px;

	@media (min-width: 768px) {
		margin-bottom: 40px;
	}

	@media (min-width: 992px) {
		margin-bottom: 60px;
	}

	@media (min-width: 1440px) {
		margin-bottom: 80px;
	}
}

.equipments__list {
	position: relative;
	display: grid;
	grid-gap: 20px;
	list-style: none;
	padding: 20px 0 80px 0;
	margin: 0;

	@media (min-width: 768px) {
		padding-bottom: 100px;
	}

	@media (min-width: 992px) {
		padding-bottom: 120px;
	}

	@media (min-width: 481px) {
		grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: -100%;
		right: -100%;
		background-color: #ffffff;
	}
}

.equipments__item {
	max-width: 650px;
	justify-self: center;
}
