// ===============================================
// CLEARFIX
// ===============================================


.clearfix {
	margin: 0;

	&::after {
		content: '';
		display: block;
		clear: both;
	}
}
