// ===============================================
// ATTACHEMENTS
// ===============================================


.attachements {
	max-width: 600px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 35px 0;
	@include style-m0;

	@media (min-width: 768px) {
		margin: 45px 0;
	}
}

.attachement {
	position: relative;
	min-height: 30px;
	font-size: 17px;
	line-height: 21px;
	text-decoration: none;
	padding-left: 35px;

	& + .attachement {
		margin-top: 25px;
	}

	&::before {
		content: '';
		position: absolute;
		top: -3px;
		left: 0;
		width: 23px;
		height: 31px;
		background-image: url('/img/icons/document.png');
		@include background-img;
		transition: transform .4s;
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			&::before {
				transform: scale(1.15);
			}

			.attachement__name {
				border-color: transparent;
			}
		}
	}
}

.attachement__name {
	border-bottom: 1px solid #0999fc;
	transition: border-color .4s;

	@media (min-width: 768px) {
		padding-bottom: 3px;
	}
}
