// ===============================================
// MISSION
// ===============================================


.mission {
	position: relative;
	color: #ffffff;
	background: linear-gradient(to right, #004990 30%, #0079d3),
	#004990;
	padding-top: 35px;
	padding-bottom: 35px;
	z-index: 1;

	@media (min-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-top: 80px;
		padding-bottom: 90px;
	}

	@media (min-width: 1440px) {
		padding-top: 120px;
		padding-bottom: 125px;
	}

	@media (max-width: 767px) {
		display: none;
	}

	&::before {
		content: url('/img/logo-transparent.svg');
		position: absolute;
		top: 15px;
		left: 50%;
		max-width: 1091px;
		width: 100%;
		height: auto;
		opacity: .05;
		pointer-events: none;
		transform: translateX(-50%);

		@media (min-width: 1440px) {
			left: 42%;
		}
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 62%;
		width: 230px;
		background-color: #5bc5f2;
		opacity: .07;
		transform: skew(-10deg);
		z-index: -1;
	}

}

.mission__inner {
	width: 100%;
	padding: 0 20px;
	margin: 0 auto;

	@media (min-width: 992px) {
		padding: 0 40px;
	}

	@media (min-width: 1281px) {
		max-width: 1400px;
		padding: 0 160px;
	}
}

.mission__content {
	position: relative;
	max-width: 825px;

	@media (min-width: 992px) {
		font-size: 15px;
		line-height: 30px;
	}
}

.mission__title {
	font-size: 40px;
	line-height: 1;
	color: #ffffff;
	margin-bottom: 20px;

	@media (min-width: 992px) {
		font-size: 48px;
		margin-bottom: 40px;
	}
}
