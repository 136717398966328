// ===============================================
// CHARACTERISTICS
// ===============================================


.characteristics {
	background-color: #ffffff;
	overflow: hidden;
	padding-top: 35px;
	padding-bottom: 35px;

	@media (min-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-top: 80px;
		padding-bottom: 90px;
	}

	@media (min-width: 1440px) {
		padding-top: 110px;
		padding-bottom: 140px;
	}
}

.characteristics__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1710px;
		padding: 0 40px;
	}

	@media (min-width: 1201px) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	@media (min-width: 1701px) {
		padding-right: 110px;
	}
}

.characteristics__content {
	@media (min-width: 1201px) {
		max-width: 990px;
		width: 100%;
		min-width: 290px;
		margin-right: 30px;
	}
}

.characteristics__title {
	margin-bottom: 30px;

	@media (min-width: 768px) {
		margin-bottom: 40px;
	}
}

.characteristics__slider {
	width: 520px;
	overflow: visible;
	margin: 40px 0 0 0;

	@media (min-width: 601px) {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: -70px;
			width: 230px;
			height: 365px;
			background-color: rgba(91, 197, 242, .24);
			transform: skew(-10deg);
		}
	}

	@media (max-width: 1200px) {
		max-width: 520px;
		width: 100%;
		margin: 40px auto 0 auto;
	}
}

.characteristics__slider-btns {
	position: absolute;
	bottom: 0;
	right: 5px;
	z-index: 1;
}

.characteristics__item-img {
	display: block;
	width: 100%;
	height: auto;
}

.characteristics__item-text {
	font-size: 14px;
	line-height: 16px;
	color: #0c2844;
	font-weight: normal;
	padding-right: 125px;
	margin-top: 20px;

	@media (min-width: 601px) {
		padding-left: 70px;
		margin-top: 25px;
	}
}


// TABLE
// -----------------------------------------------
.table-responsive {
	overflow-x: auto;
	overflow-y: hidden;
	width: 100%;
	display: block;
	margin-top: 30px;
	margin-bottom: 30px;
	clear: both;

	@media (min-width: 992px) {
		margin-top: 55px;
		margin-bottom: 55px;
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&::-webkit-scrollbar {
		width: 16px;
		background: #ffffff;
		border-radius: 20px;
		padding: 5px;
		box-shadow: -2px 3px 4px 0px rgba(3, 88, 113, 0.32) inset;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #0b99c3;
		background-clip: padding-box;
		border: 4px solid transparent;
		border-radius: 20px;
	}

	&::-webkit-scrollbar-track {
		margin: 5px;
	}
}

.table {
	width: 100%;
	border-spacing: 0;
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	color: #0c2844;

	@media (min-width: 992px) {
		font-size: 16px;
		line-height: 24px;
	}

	thead {
		position: sticky;
		top: 0;
		background-color: #ffffff;
	}

	tr {
		&:nth-child(2n+2) {
			background-color: #f0fafe;
		}
	}

	th {
		min-width: 70px;
		font-size: 12px;
		line-height: 16px;
		font-weight: normal;
		color: #0c2844;
		text-align: left;
		border-bottom: 2px solid #e3e5ee;
		padding: 0 10px 5px 10px;

		&:first-child {
			min-width: 110px;
			padding-left: 5px;
		}

		&:last-child {
			padding-right: 5px;
		}
	}

	td {
		min-width: 70px;
		vertical-align: middle;
		border-bottom: 2px solid #e3e5ee;
		padding: 10px 10px;

		&:first-child {
			min-width: 110px;
			font-size: 13px;
			line-height: 15px;
			color: #74889c;
			padding-left: 5px;
		}

		&:last-child {
			padding-right: 5px;
		}
	}
}

.characteristics__table {
	max-height: 480px;
	overflow-y: auto;
}
// -----------------------------------------------
// TABLE
