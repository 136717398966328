// ===============================================
// FEEDBACK
// ===============================================


.feedback {
	background-color: #ffffff;
	overflow: hidden;

	&--index {
		.feedback__inner {
			@media (min-width: 1281px) {
				max-width: 1590px;
				padding-left: 160px;
			}

			@media (min-width: 1750px) {
				max-width: 1710px;
				padding-right: 160px;
			}
		}

		.feedback__content {
			@media (min-width: 1281px) and (max-width: 1440px) {
				padding-left: 70px;
			}
		}
	}

	@media (max-width: 991px) {
		display: none;
	}
}

.feedback__inner {
	max-width: 1470px;
	width: 100%;
	margin: 0 auto;
	padding: 0 40px;
}

.feedback__tabs {
	display: flex;
}

.feedback__block {
	position: relative;
	width: 1500px;
	overflow: hidden;
	transform: skew(-10deg);
	flex-shrink: 0;
	margin-left: -1130px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		width: 250%;
		background: linear-gradient(-135deg, #0079d3 -50px, #004990 500px), #004990;
	}
}

.feedback__labels {
	width: 420px;
	transform: skew(10deg);
	list-style: none;
	padding: 0;
	margin: 0;
	margin-left: auto;
	margin-right: -50px;
}

.feedback__label {
	position: relative;
}

.feedback__label-input {
	@include visually-hidden;

	&:checked {
		& + .feedback__label-button {
			font-size: 26px;
			color: #ffffff;

			&::before {
				opacity: 1;
			}

			&::after {
				opacity: 1;
				transform: translateX(0);
			}

			span {
				display: block;
			}
		}
	}
}

.feedback__label-button {
	display: block;
	font-size: 18px;
	line-height: 1;
	color: #41a8ed;
	background-color: transparent;
	cursor: pointer;
	padding: 50px 0;
	z-index: 1;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 300%;
		opacity: 0;
		background-color: rgb(91, 197, 242, .25);
		border-top: 2px solid rgb(91, 197, 242, .25);
		border-bottom: 2px solid rgb(91, 197, 242, .25);
		z-index: -1;
	}

	span {
		display: none;
		font-size: 13px;
		color: #ffffff;
		margin-top: 10px;
	}
}

.feedback__content {
	padding: 40px 0 70px 70px;

	@media (min-width: 1281px) {
		padding-left: 120px;
	}
}

.feedback__title {
	font-size: 26px;
	line-height: 31px;
	margin-bottom: 25px;
}

.feedback__form {
	margin-top: 45px;
}

.feedback__button {
	margin-top: 20px;
}
