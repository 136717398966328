// ===============================================
// CONTACTS-US
// ===============================================


.contact-us {
	position: fixed;
	bottom: 20px;
	right: 0;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	z-index: 150;

	@media (max-width: 480px) {
		left: 0;
		bottom: 0;
		background-color: rgba(255,255,255, .4);
		justify-content: space-between;
		padding: 10px 20px;
	}

	@media (min-width: 1281px) {
		display: none;
	}

	&--main {
		@media (min-width: 1281px) {
			display: flex;
			flex-direction: column;
			bottom: auto;
			top: 245px;
			left: 10px;
			right: auto;

			.contact-us__item {
				margin-bottom: 20px;
			}
		}

		@media (min-width: 1920px) {
			left: 75px;
		}
	}
}

.contact-us__item {
	display: block;
	text-decoration: none;
	transition: transform .4s;
	margin-right: 20px;

	&--text {
		max-width: 130px;
		display: flex;
		align-items: center;

		.contact-us__icon {
			flex-shrink: 0;
		}
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			animation: 1.2s ease-in-out trambling;
		}

		&--text {
			&:hover,
			&:focus {
				animation: 1.2s ease-in-out trambling-text;
			}
		}
	}
}

.contact-us__icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
	background-color: #64befb;
	border-radius: 50%;

	img {
		display: block;
		max-width: 44px;
		max-height: 44px;
	}
}

.contact-us__text {
	display: inline-block;
	font-size: 12px;
	line-height: 15px;
	font-style: italic;
	color: #1a1b29;
	margin-left: 10px;

	@media (max-width: 1280px) {
		display: none;
	}
}


@keyframes trambling {
	0%,
	50%,
	100% {
		transform: rotate(0deg);
	}

	10%,
	30% {
		transform: rotate(-15deg);
	}

	20%,
	40% {
		transform: rotate(15deg);
	}
}

@keyframes trambling-text {

	0%,
	50%,
	100% {
		transform: rotate(0deg);
	}

	10%,
	30% {
		transform: rotate(-5deg);
	}

	20%,
	40% {
		transform: rotate(5deg);
	}
}
