// ===============================================
// FONTS
// ===============================================


@font-face {
	font-family: 'Gotham Pro';
	src: local('GothamPro-Bold'),
		url('/fonts/GothamPro-Bold.woff2') format('woff2'),
		url('/fonts/GothamPro-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham Pro';
	src: local('GothamPro-BoldItalic'),
		url('/fonts/GothamPro-BoldItalic.woff2') format('woff2'),
		url('/fonts/GothamPro-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham Pro';
	src: local('GothamPro-LightItalic'),
		url('/fonts/GothamPro-LightItalic.woff2') format('woff2'),
		url('/fonts/GothamPro-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham Pro';
	src: local('GothamPro'),
		url('/fonts/GothamPro.woff2') format('woff2'),
		url('/fonts/GothamPro.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham Pro';
	src: local('GothamPro-Light'),
		url('/fonts/GothamPro-Light.woff2') format('woff2'),
		url('/fonts/GothamPro-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham Pro';
	src: local('GothamPro-Medium'),
		url('/fonts/GothamPro-Medium.woff2') format('woff2'),
		url('/fonts/GothamPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham Pro';
	src: local('GothamPro-Italic'),
		url('/fonts/GothamPro-Italic.woff2') format('woff2'),
		url('/fonts/GothamPro-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham Pro';
	src: local('GothamPro-MediumItalic'),
		url('/fonts/GothamPro-MediumItalic.woff2') format('woff2'),
		url('/fonts/GothamPro-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica-6';
	src: local('Helvetica-6-Medium'),
		url('/fonts/Helvetica-6-Medium.woff2') format('woff2'),
		url('/fonts/Helvetica-6-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
