// ===============================================
// LINKS
// ===============================================


// Set default link style

a {
	color: #0999fc;
	transition: color .4s;

	&[href*="tel:"] {
		text-decoration: none;
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}
