// ===============================================
// APPLICATION-AREA
// ===============================================


.application-area {
	overflow: hidden;
	padding-bottom: 35px;

	@media (max-width: 767px) {
		padding-top: 25px;
	}

	@media (min-width: 768px) {
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-bottom: 100px;
	}
}

.application-area__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1830px;
		padding: 0 40px;
	}
}

.application-area__list {
	margin-top: 30px;

	@media (min-width: 768px) {
		margin-top: 50px;
	}

	@media (min-width: 992px) {
		margin-top: 80px;
	}

	@media (min-width: 1440px) {
		margin-top: 130px;
	}
}
