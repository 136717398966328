// ===============================================
// CARD
// ===============================================


.card {
	position: relative;
	max-width: 405px;
	width: 100%;
	overflow: hidden;

	@media (hover: hover) {
		&:hover,
		&:focus-within {
			.card__title {
				color: #0999fc;
			}

			.card__img {
				img {
					transform: scale(1.05);
				}
			}

			.card__link {
				&::before {
					transform: skew(-10deg) translate(-95%);
				}
			}
		}
	}
}

.card__img {
	position: relative;
	width: 100%;
	height: 180px;

	@media (min-width: 768px) {
		height: 230px;
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 50%;
		background: linear-gradient(to top, rgba(91,197,242, .4), transparent);
	}

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transform-origin: center;
		transition: transform .4s;
	}
}

.card__text {
	position: relative;
	background-color: #ffffff;
	padding: 30px 25px 40px 25px;

	@media (min-width: 768px) {
		padding: 55px 35px 45px 35px;
	}
}

.card__title {
	color: #1d2429;
	transition: color .4s;

	@media (min-width: 768px) and (max-width: 991px) {
		font-size: 18px;
		line-height: 21px;
	}

	@media (min-width: 768px) {
		min-height: 63px;
	}
}

.card__link {
	@include style-cover;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 110%;
		width: 80%;
		bottom: 0;
		background-color: rgba(91,197,242, .12);
		transform: skew(-10deg);
		transition: transform .4s;
	}
}

.card__description {
	font-size: 14px;
	line-height: 18px;
	color: #848b92;
	padding-right: 55px;
	margin-top: 25px;

	@media (min-width: 768px) {
		padding-right: 75px;
		margin-top: 40px;
	}
}

.card__arrow {
	position: absolute;
	bottom: 20px;
	right: 25px;
	width: 45px;
	height: 28px;
	background-color: #0999fc;
	transform: skew(-10deg);

	@media (min-width: 768px) {
		right: 45px;
		bottom: 35px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 5px;
		width: 37px;
		height: 18px;
		background-image: url('/img/icons/arrows.png');
		@include background-img;
		opacity: .34;
		transform: skew(10deg) rotate(180deg) scale(-1, 1);
	}
}

.cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
	grid-gap: 40px 15px;
	list-style: none;
	padding: 0;
	margin: 0;

	@media (min-width: 768px) {
		grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
		grid-gap: 40px 20px;
	}

	.card {
		justify-self: center;
	}
}
