// ===============================================
// SOCIALS
// ===============================================


.socials {
	display: flex;
	align-items: center;
}

.socials__item {
	position: relative;
	margin-left: 50px;

	&::before {
		content: '';
		position: absolute;
		top: 50%;
		left: -25px;
		height: 23px;
		width: 2px;
		background-color: rgba(91, 197, 242, .2);
		transform: skew(-10deg) translateY(-50%);
	}
}

.social {
	display: block;
}

.social__icon {
	display: block;
	max-width: 20px;
	max-height: 20px;
}
