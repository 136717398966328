// ===============================================
// GALLERY
// ===============================================


.gallery {
	position: relative;
	background-color: #EBECF0;
	overflow: hidden;
	padding-top: 35px;
	padding-bottom: 35px;
	z-index: 1;

	@media (hover: hover) {
		overflow: visible;
	}

	@media (min-width: 768px) {
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-bottom: 90px;
	}

	@media (min-width: 1440px) {
		padding-bottom: 130px;
	}
}

.gallery__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1710px;
		padding: 0 40px;
	}
}

.gallery__title {
	width: 100%;
	max-width: 1460px;
	margin: 0 auto 30px auto;
}

.gallery__slider {
	width: 100%;
	max-width: 1460px;
	height: 675px;
	overflow: visible;
	margin: 0 auto;

	@media (min-width: 992px) {
		height: 705px;
	}

	.swiper-slide {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 170px;
	}
}

.gallery__item {
	position: relative;
	display: block;
	width: 100%;
	text-decoration: none;
	outline: none;
	user-select: none;

	&--hover {
		.gallery__item-block {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
		}
	}

	&--anim {
		.gallery__item-img {
			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 15px;
				height: 100%;
				width: 140px;
				background-color: rgba(91,197,242, .32);
				transform: skew(-10deg) translateX(180px);
				transition: transform .4s;
				z-index: 1;
			}
		}

		@media (hover: hover) {
			&:hover,
			&:focus {
				.gallery__item-title {
					color: #0999fc;
				}

				.gallery__item-img {
					&::after {
						transform: skew(-10deg) translateX(0);
					}

					img {
						transform: scale(1.2);
					}
				}
			}
		}
	}

	&:first-child {
		.gallery__item-block {
			bottom: auto;
			top: 30%;
			transform-origin: 0 0;

			&--reverse {
				transform-origin: 100% 0;
			}
		}
	}
}

.gallery__item-img {
	position: relative;
	width: 170px;
	height: 165px;
	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: transform .4s;
	}
}

.gallery__item-title {
	font-size: 13px;
	line-height: 1.2;
	height: 32px;
	color: #1d2429;
	transition: color .4s;
	margin-top: 15px;
}

.gallery__item-block {
	position: absolute;
	bottom: 22%;
	left: 30%;
	font-size: 12px;
	line-height: 1.2;
	color: #0c2844;
	background-color: #ffffff;
	border: 1px solid #1d2429;
	opacity: 0;
	visibility: hidden;
	transform-origin: 0 100%;
	transform: scale(0);
	transition: all .4s;
	padding: 10px 10px 25px 10px;
	box-shadow: 0 70px 80px -55px rgba(14, 51, 90, .4);
	z-index: 1;

	&--reverse {
		transform-origin: 100% 100%;
		left: auto;
		right: 30%;
	}

	img {
		display: block;
		max-width: 660px;
		max-height: 500px;
		width: auto;
		height: auto;
		margin-bottom: 10px;
	}

	h4 {
		height: 32px;
		font-size: 14px;
		line-height: 1.2;
		font-weight: 500;
		color: #1d2429;
		overflow: hidden;
		margin-bottom: 2px;
	}
}

.pswp__bg {
	background-color: rgba(175,192,209, .58);
}

.pswp__custom-caption {
	font-family: "Gotham Pro";
	background: #ffffff;
	font-size: 12px;
	line-height: 1.2;
	color: #1d2429;
	font-weight: normal;
	width: 100%;
	padding: 15px 10px 30px 10px;
	position: absolute;
	left: 0;
	bottom: 0;
	text-align: center;

	span {
		display: block;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 5px;
	}
}

.gallery__item-text {
	display: none;
}
