// ===============================================
// INTRO
// ===============================================


.intro {
     max-width: 1900px;
     width: 100%;
     padding-bottom: 35px;
     margin: 0 auto;

     @media (min-width: 768px) {
          padding-bottom: 50px;
     }

     @media (min-width: 1440px) {
          margin-top: -25px;
     }
}

.intro__inner {
     position: relative;
     max-width: 1660px;
     width: 100%;

     @media (min-width: 992px) {
          height: 520px;
          padding-left: 20px;
          margin-left: auto;
          margin-right: -60px;
     }

     @media (min-width: 1281px) {
          padding-left: 100px;
     }

     @media (min-width: 1440px) {
          height: 570px;
     }

     @media (min-width: 1811px) {
          height: 620px;
          padding-left: 0;
     }

     .slider-button {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: skew(-10deg) translateY(-50%);
          z-index: 2;

          @media (min-width: 992px) {
               right: 80px;
          }

          @media (min-width: 1440px) {
               right: 150px;
          }

          @media (max-width: 767px) {
               display: none;
          }
     }
}

.intro__content {
     width: 100%;
     height: 100%;
     overflow: hidden;

     @media (min-width: 992px) {
          transform: skew(-10deg);
     }
}

.intro__fon {
     position: absolute;
     @include style-cover;

     &::before {
          content: '';
          @include style-cover;
          background-color: #004990;
          opacity: .36;
          z-index: 2;
     }

     @media (min-width: 992px) {
          transform: skew(10deg) translateX(-60px);
     }
}

.intro__fon-img {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     object-fit: cover;
     transition: clip-path 0s;
     clip-path: polygon(0% 0%, 0% 0%, -8% 100%, 0% 100%);

     &--active {
          transition: clip-path 1s linear;
          clip-path: polygon(0% 0%, 108% 0%, 100% 100%, 0% 100%);
     }

     &--now {
          z-index: 1;
     }
}

.intro__slider {
     overflow: visible;
     margin: 0;

     @media (min-width: 992px) {
          position: absolute;
          width: 75%;
          top: 160px;
          left: 70px;
     }

     @media (min-width: 1281px) {
          width: 60%;
          left: 200px;
     }

     @media (min-width: 1811px) {
          width: 75%;
          left: 165px;
     }

     @media (max-width: 991px) {
          position: relative;
          padding: 80px 180px 80px 20px;
          z-index: 2;
     }

     @media (max-width: 767px) {
          position: relative;
          padding: 40px 15px 40px 15px;
          z-index: 2;
     }
}

.intro__slider-item {
}

.intro__slider-title {
     font-size: 28px;
     line-height: 30px;
     text-transform: uppercase;
     color: #ffffff;

     @media (min-width: 481px) {
          font-size: 38px;
          line-height: 1;
     }

     @media (min-width: 768px) and (max-width: 991px) {
          font-size: 42px;
     }

     @media (min-width: 992px) and (max-width: 1439px) {
          font-size: 45px;
     }

     @media (min-width: 1440px) and (max-width: 1810px) {
          font-size: 50px;
     }

     @media (min-width: 1811px) {
          font-size: 60px;
     }

     @media (max-width: 991px) {
          br {
               display: none;
          }
     }
}

.intro__slider-button {
     margin-top: 20px;

     @media (min-width: 768px) {
          margin-top: 40px;
     }

     @media (min-width: 992px) {
          margin-top: 80px;
     }
}

.intro__list {
     display: grid;
     grid-template-columns: repeat(3, 1fr);
     align-items: center;
     grid-gap: 10px 15px;
     width: 100%;
     color: #1d2429;
     font-size: 12px;
     line-height: 16px;
     list-style: none;
     padding: 0 5px;
     margin: 0;
     margin: 25px auto 0 auto;

     @media (min-width: 481px) {
          font-size: 14px;
          line-height: 20px;
          grid-gap: 10px 20px;
          padding: 0 15px;
     }

     @media (min-width: 601px) {
          justify-items: center;
          font-size: 18px;
          line-height: 21px;
     }

     @media (min-width: 768px) {
          margin-top: 45px;
          padding: 0 20px;
     }

     @media (min-width: 992px) {
          max-width: 1590px;
          padding: 0 40px;
     }

     @media (min-width: 1201px) {
          grid-template-columns: repeat(6, 1fr);
     }

     @media (min-width: 1281px) and (max-width: 1810px) {
          padding-left: 160px;
          max-width: 1670px;
     }

     @media (max-width: 374px) {
          grid-template-columns: repeat(2, 1fr);
          padding: 0 15px;
     }
}

.intro__item {
     max-width: 200px;
}

.intro__number {
     display: block;
     color: #004990;
     font-size: 32px;
     line-height: 1;
     margin-bottom: 10px;

     @media (min-width: 481px) {
          font-size: 40px;
     }

     @media (min-width: 601px) {
          font-size: 50px;
     }

     @media (min-width: 768px) {
          font-size: 60px;
     }
}

.intro__text {
     position: relative;
     padding-left: 20px;
     margin-top: 0;

     @media (min-width: 601px) {
          padding-left: 30px;
     }

     &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          height: 46px;
          width: 4px;
          background-color: #004990;
          transform: translateY(-50%) skew(-10deg);
     }
}
