// ===============================================
// BREADCRUMBS
// ===============================================


.breadcrumbs {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
	margin: 0;

	& + h1 {
		margin-top: 15px;

		@media (min-width: 768px) {
			margin-top: 20px;
		}
	}

	@media (max-width: 991px) {
		overflow: hidden;
		overflow-x: scroll;
		flex-wrap: nowrap;
		-ms-overflow-style: none;
		scrollbar-width: none;
		white-space: nowrap;
		padding-left: 20px;
		margin: 0 -20px;

		&::-webkit-scrollbar {
			height: 0px;
			background: transparent;
		}
	}

	@media (max-width: 767px) {
		padding-left: 15px;
		margin: 0 -15px;
	}
}

.breadcrumbs__item {
	font-size: 12px;
	line-height: 16px;
	margin-right: 35px;

	@media (min-width: 768px) {
		font-size: 14px;
		line-height: 20px;
		margin-right: 45px;
	}

	&:not(:first-child) {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 6px;
			left: -23px;
			width: 10px;
			height: 5px;
			background-image: url('/img/icons/arrows-grey.png');
			@include background-img;

			@media (min-width: 768px) {
				top: 8px;
				left: -27px;
				width: 12px;
				height: 6px;
			}
		}
	}
}

.breadcrumbs__link {
	display: inline-block;
	color: #848b92;
	text-decoration: none;
}

a.breadcrumbs__link {
	@media (hover: hover) {
		&:hover,
		&:focus {
			color: #0999fc;
		}
	}
}
