// ===============================================
// BUTTONS
// ===============================================


button {
	-webkit-appearance: none;
	border: none;
	cursor: pointer;
	background-color: transparent;
	padding: 0;

	&:active
	&:focus {
		box-shadow: none;
	}
}


// BUTTON
// -----------------------------------------------
.button {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-family: 'Gotham Pro';
	min-width: 150px;
	font-size: 12px;
	font-weight: 500;
	line-height: 1;
	color: #ffffff;
	background-color: transparent;
	text-transform: uppercase;
	vertical-align: middle;
	text-align: center;
	text-decoration: none;
	user-select: none;
	padding: 15px 35px 15px 25px;
	z-index: 1;

	&::before {
		content: '';
		@include style-cover;
		background-color: #0999fc;
		transition: background-color .4s;
		transform: skew(-10deg);
		z-index: -1;
	}

	&::after {
		content: '';
		position: absolute;
		right: 1px;
		top: 0;
		width: 37px;
		height: 18px;
		background-image: url('/img/icons/arrows.png');
		@include background-img;
		transform: scale(1, -1);
		opacity: .34;
	}

	&--large {
		min-width: 240px;
		font-size: 16px;
		padding-left: 35px;
		padding-right: 45px;

		&::after {
			right: 10px;
		}
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			&::before {
				background-color: #5bc5f2;
			}

		}
	}

	&[disabled] {
		opacity: 0.8;

		&:not(a) {
			cursor: not-allowed;
		}
	}
}
// -----------------------------------------------
// BUTTON


// MENU-BUTTON
// -----------------------------------------------
.btn {
	width: 40px;
	height: 35px;
	background-color: #004990;
	transform: skew(-10deg);

	@media (min-width: 768px) {
		width: 60px;
		height: 55px;
	}

	&--light {
		background-color: #0999fc;
	}

	&--close {
		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			width: 25px;
			height: 2px;
			background-color: #ffffff;

			@media (min-width: 768px) {
				width: 30px;
			}
		}

		&::before {
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after {
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		@media (min-width: 768px) {
			width: 50px;
			height: 45px;
		}
	}

	&--menu {
		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 50%;
			width: 32px;
			height: 2px;
			background-color: #ffffff;
		}

		&::before {
			top: 12px;
			left: 50%;
			width: 28px;
			transform: translateX(-50%);

			@media (min-width: 768px) {
				top: 21px;
				width: 32px;
			}
		}

		&::after {
			bottom: 12px;
			width: 16px;
			transform: translateX(-3px);

			@media (min-width: 768px) {
				bottom: 22px;
				width: 18px;
			}
		}
	}


}
// -----------------------------------------------
// MENU-BUTTON


// SLIDER-BUTTON
// -----------------------------------------------
.slider-button {
	position: relative;
	width: 140px;
	height: 82px;
	overflow: hidden;

	&.swiper-button-disabled {
		display: none;
	}
}

.slider-button__img {
	position: absolute;
	top: 0;
	left: -10px;
	width: 160px;
	height: 100%;
	object-fit: cover;
	transform: skew(10deg);
	opacity: 0;
	transition: .4s;

	&--active {
		opacity: 1;
	}
}
// -----------------------------------------------
// SLIDER-BUTTON


// SLIDER-BTN
// -----------------------------------------------
.slider-btns {
	display: flex;
	justify-content: space-between;
	width: 95px;

	@media (min-width: 768px) {
		width: 102px;
	}
}

.slider-btn {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38px;
	width: 41px;
	transition: opacity .4s;

	&.swiper-button-disabled {
		opacity: 0;
		pointer-events: none;
	}

	@media (min-width: 768px) {
		height: 45px;
		width: 48px;
	}

	&::before {
		content: '';
		@include style-cover;
		background-color: #0999FC;
		transform: skew(-10deg);
		z-index: -1;
	}

	&--prev {
		.slider-btn__arrow {
			transform: rotate(95deg);
		}
	}

	@media (hover: hover) {
		&::before {
			background-color: rgba(9, 153, 252, .15);
			transition: background-color .4s;
		}
		&:hover,
		&:focus {
			&::before {
				background-color: #0999FC;
			}
		}
	}
}

.slider-btn__arrow {
	display: block;
	width: 20px;
	height: 13px;
	background-image: url('/img/icons/arrow.png');
	@include background-img;
	transform: rotate(-90deg);

	@media (min-width: 768px) {
		width: 26px;
		height: 17px;
	}
}
// -----------------------------------------------
// SLIDER-BTN


// BUTTON-FIXED
// -----------------------------------------------
.button-fixed {
	position: fixed;
	right: 0;
	top: 450px;
	display: flex;
	align-items: center;
	font-size: 16px;
	line-height: 1;
	color: #ffffff;
	text-decoration: none;
	background: linear-gradient(to bottom, #014485, #0283d7);
	transform: rotate(90deg);
	transform-origin: 100% 0;
	padding: 15px 30px 15px 15px;
	box-shadow: 5px 8px 30px -10px #0283d7;
	overflow: hidden;
	transition: box-shadow .4s;
	z-index: 100;

	@media (max-width: 991px) {
		display: none;
	}

	&::before {
		content: '';
		flex-shrink: 0;
		margin-right: 15px;
		width: 18px;
		height: 24px;
		background-image: url('/img/icons/document-light.png');
		@include background-img;
	}

	&::after {
		content: '';
		position: absolute;
		height: 100%;
		bottom: 0;
		background: linear-gradient(-90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2) 100%);
		width: 45px;
		transform: skewX(-10deg);
		right: -100px;
		animation-name: blick;
		animation-duration: 2.6s;
		animation-iteration-count: infinite;
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			box-shadow: none;
		}
	}
}

@keyframes blick {
	0% {
		transform: skewX(-10deg) translateY(0px);
	}

	100% {
		transform: skewX(-10deg) translateX(-500px);
	}
}
// -----------------------------------------------
// BUTTON-FIXED
