// ===============================================
// TYPOGRAPHY
// ===============================================


// HEADINGS
// -----------------------------------------------

h1,
.h1 {
	@include style-text-h1;
}

h2,
.h2 {
	@include style-text-h2;
}

h3,
.h3 {
	@include style-text-h3;
}

h4,
.h4,
h5,
h6 {
	@include style-text-h4;
}


// -----------------------------------------------
// HEADINGS


// PARAGRAPH
// -----------------------------------------------
p {
	margin: 0;
}

p + p {
	margin-top: 15px;
}

strong,
b {
	font-weight: bold;
}

i {
	font-style: italic;
}

.email-link {
	position: relative;
	display: inline-block;
	font-size: 15px;
	line-height: 20px;
	border-bottom: 1px solid #0999fc;
	text-decoration: none;
	transition: border-color .4s;
	margin-left: 30px;

	@media (min-width: 992px) {
		font-size: 17px;
		line-height: 21px;
		margin-left: 35px;
		padding-bottom: 3px;
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			border-color: transparent;
		}
	}

	&::before {
		content: '';
		position: absolute;
		top: -1px;
		left: -25px;
		width: 30px;
		height: 21px;
		background-image: url('/img/icons/email.png');
		background-position: left center;
		background-size: 21px 21px;
		background-repeat: no-repeat;
		padding-right: 9px;

		@media (min-width: 992px) {
			left: -30px;
			width: 35px;
			height: 25px;
			background-size: 25px 25px;
			padding-right: 10px;
		}
	}
}

.lead {
	font-size: 16px;
	line-height: 24px;
	font-weight: bold;

	@media (min-width: 992px) {
		font-size: 18px;
		line-height: 30px;
	}
}

.presentation {
	@media (min-width: 601px) {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
}

.presentation__name {
	@include style-text-h3;

	@media (min-width: 601px) {
		padding: 20px 0;
		margin-right: 70px;
	}

	@media (max-width: 600px) {
		display: block;
		width: 100%;
		margin-bottom: 20px;
	}
}

.presentation__link {
	position: relative;
	min-width: 305px;
	display: inline-block;
	font-size: 14px;
	line-height: 16px;
	color: #ffffff;
	text-decoration: none;
	text-align: center;
	padding: 20px 70px;

	@media (max-width: 374px) {
		min-width: 290px;
	}

	&::before {
		content: '';
		@include style-cover;
		background-color: #0999fc;
		transition: background-color .4s;
		transform: skew(-10deg);
		z-index: -1;
	}

	&::after {
		content: '';
		position: absolute;
		left: 30px;
		top: 50%;
		width: 24px;
		height: 32px;
		background-image: url('/img/icons/presentation.png');
		@include background-img;
		transform: translateY(-50%);
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			&::before {
				background-color: #5bc5f2;
			}

		}
	}
}

// -----------------------------------------------
// PARAGRAPH
