// ===============================================
// ICONS
// ===============================================


.icon {
	display: inline-block;
	font-size: 18px;
	width: 1em;
	height: 1em;
}
