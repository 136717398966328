// ===============================================
// SELECTION
// ===============================================


.selection {
	position: relative;
	padding-top: 25px;
	padding-bottom: 35px;
	z-index: 1;

	@media (min-width: 768px) {
		padding-top: 0;
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-bottom: 80px;

		&::before {
			content: '';
			position: absolute;
			right: 13%;
			bottom: 0;
			width: 230px;
			height: 490px;
			background-color: rgb(91, 197, 242, .14);
			transform: skew(-10deg);
			z-index: -1;
		}
	}

	@media (min-width: 1440px) {
		padding-bottom: 100px;
	}
}

.selection__inner {

	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1710px;
		padding: 0 40px;
	}

	@media (min-width: 1201px) {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}

	@media (min-width: 1440px) {
		padding-right: 140px;
	}
}

.selection__text {
	@media (min-width: 1201px) {
		max-width: 960px;
		width: 100%;
		margin-right: 30px;
	}
}

.selection__title {
	margin-bottom: 25px;
}

.selection__intro {
	max-width: 780px;
	font-size: 18px;
	line-height: 24px;
	color: #1d2429;
	margin-bottom: 20px;

	@media (min-width: 992px) {
		font-size: 24px;
		line-height: 29px;
		margin-bottom: 40px;
	}
}

.selection__block {
	position: relative;
	max-width: 480px;
	font-size: 14px;
	line-height: 18px;
	color: #ffffff;
	background-color: #004990;

	&::before {
		content: url('/img/logo-star-light.svg');
		position: absolute;
		top: -110px;
		left: -240px;
		width: 409px;
		height: auto;
		opacity: .12;
	}

	@media (min-width: 481px) {
		transform: skew(-10deg);
	}

	@media (max-width: 1200px) {
		margin: 40px auto 0 auto;
	}
}

.selection__block-text {
	position: relative;
	width: 100%;
	padding: 30px 20px;
	overflow: hidden;
	z-index: 1;

	@media (min-width: 481px) {
		transform: skew(10deg);
		min-height: 230px;
		padding: 50px 60px 50px 70px;
	}
}

.selection__block-phone {
	display: inline-block;
	font-family: 'Helvetica-6';
	font-size: 30px;
	line-height: 1;
	color: #ffffff;
	margin-top: 25px;
}
