// ===============================================
// LOGO
// ===============================================


.logo {
	text-decoration: none;

	&--light {
		.logo__description {
			color: #ffffff;
		}

		@media (max-width: 767px) {
			margin-bottom: 0;
		}
	}

	@media (min-width: 768px) {
		display: flex;
		align-items: center;
	}
}

.logo__img {
	display: block;
	width: 150px;

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	@media (min-width: 768px) {
		width: 228px;
	}
}

.logo__text {
	@media (max-width: 767px) {
		display: none;
	}
}

.logo__description {
	display: block;
	font-size: 16px;
	line-height: 1;
	font-style: italic;
	color: #1a1b29;
	margin-left: 10px;
}
