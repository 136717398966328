// ===============================================
// ABOUT
// ===============================================


.about {
	padding-top: 25px;
	padding-bottom: 35px;

	@media (min-width: 768px) {
		padding-top: 0;
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-bottom: 110px;
	}
}

.about__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1740px;
		padding: 0 40px;
	}
}

.about__title {
	margin-bottom: 20px;
}

.about__content {
	margin-top: 30px;

	@media (min-width: 768px) {
		margin-top: 50px;
	}

	@media (min-width: 1201px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.about__list {
	max-width: 680px;
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;

	@media (max-width: 1200px) {
		margin: 0 auto;
	}

	@media (min-width: 1201px) {
		padding-top: 10px;
	}
}

.about__item {
	@media (min-width: 601px) {
		display: flex;
		align-items: center;
	}

	& + .about__item {
		margin-top: 30px;

		@media (min-width: 601px) {
			margin-top: 50px;
		}

		@media (min-width: 1440px) {
			margin-top: 80px;
		}
	}

	@media (max-width: 600px) {
		text-align: center;
	}
}

.about__number {
	width: 170px;
	font-size: 18px;
	line-height: 21px;

	@media (min-width: 601px) {
		flex-shrink: 0;
		margin-right: 20px;
	}

	@media (min-width: 1201px) {
		margin-right: 40px;
	}

	@media (max-width: 600px) {
		margin: 0 auto 10px auto;;
	}

	span {
		display: block;
		color: #004990;
		font-size: 60px;
		line-height: 1;

		@media (min-width: 601px) {
			margin-bottom: 10px;
		}
	}

	&--small {
		span {
			font-size: 48px;
		}
	}
}

.about__text {
	margin-top: 0;
}

.about__slider {
	max-width: 835px;

	@media (min-width: 1201px) {
		width: 50%;
		margin-left: 30px;
	}

	@media (max-width: 1200px) {
		margin: 50px auto 0 auto;
	}

	@media (max-width: 600px) {
		margin-top: 25px;
	}
}
