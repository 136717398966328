// ===============================================
// DIRECTION
// ===============================================


.direction {
	position: relative;
	display: flex;
	align-items: flex-end;
	height: 150px;
	width: 100%;
	max-width: 700px;
	background-color: transparent;
	padding: 30px 20px 30px 10px;
	z-index: 1;

	@media (min-width: 601px) {
		height: 230px;
		padding: 35px 35px 35px 25px;
	}

	@media (hover: hover) {
		&:hover,
		&:focus-within {
			.direction__fon {
				background-color: #0999fc;

				&::after {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&--reverse {
		flex-direction: row-reverse;

		.direction__fon {
			content: '';
			position: absolute;
			left: auto;
			right: 0;

			&::after {
				left: auto;
				right: -120px;

				@media (min-width: 601px) {
					right: -220px;
				}
			}
		}

		.direction__title {
			@media (min-width: 481px) {
				margin-left: 15px;
				margin-right: 0;
			}

			@media (min-width: 1440px) {
				margin-left: 30px;
			}
		}

		@media (max-width: 1200px) {
			margin-left: auto;
		}
	}
}

.direction__fon {
	position: absolute;
	left: 0;
	bottom: 0;
	top: 0;
	width: 75%;
	max-width: 475px;
	background-color: #004990;
	transition: background-color .4s;
	transform: skew(-10deg);
	overflow: hidden;
	z-index: -1;

	&::after {
		content: '';
		position: absolute;
		top: -50px;
		left: -130px;
		width: 220px;
		height: 219px;
		background-image: url('/img/logo-star-light.svg');
		@include background-img;
		transform-origin: center;
		transition: transform .4s;
		opacity: .05;
		pointer-events: none;

		@media (min-width: 601px) {
			top: -110px;
			left: -235px;
			width: 409px;
			height: 406px;
		}
	}
}

.direction__title {
	flex-shrink: 0;
	text-transform: uppercase;

	@media (min-width: 481px) {
		margin-right: 15px;
	}

	@media (min-width: 1440px) {
		margin-right: 30px;
	}
}

.direction__link {
	color: #ffffff;
	text-decoration: none;

	&::before {
		content: '';
		@include style-cover;
		z-index: -1;
	}
}

.direction__img {
	pointer-events: none;
	max-width: 80%;
	box-shadow: 0 30px 20px -25px rgba(0, 73, 144, .5);
	margin-bottom: -30px;

	@media (min-width: 601px) {
		margin-bottom: -35px;
	}

	@media (min-width: 481px) and (max-width: 600px) {
		max-width: 65%;
	}

	@media (min-width: 601px) and (max-width: 1439px) {
		max-width: 75%;
	}
}
