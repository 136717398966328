// ===============================================
// FORMS
// ===============================================


.form-group__input,
.form-group__textarea {
	display: inline-flex;
	height: 57px;
	width: 100%;
	font-family: 'Gotham Pro';
	font-size: 15px;
	line-height: 1;
	font-weight: normal;
	color: #36617f;
	background-color: transparent;
	border: none;
	outline: none;
	border: 1px solid #c5d6e2;
	padding: 0 30px;
	transition: border-color .4s;

	&:active,
	&:focus {
		border-color: #004990;

		& + .form__group-title {
			opacity: 0;
		}
	}

	&::placeholder {
		font-size: 15px;
		line-height: 1;
		color: #36617f;
	}
}

.form {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	max-width: 565px;
	width: 100%;

	&--file {
		position: relative;

		@media (min-width: 1201px) {
			max-width: 700px;
		}

		.form__group {
			@media (min-width: 1201px) {
				margin-right: 315px;
			}

			&--file {
				max-width: 270px;
				margin-top: 10px;
				margin-right: 0;

				@media (min-width: 1201px) {
					position: absolute;
					top: 5px;
					right: 0;
				}
			}
		}
	}
}

.form__group {
	position: relative;
	width: 100%;
	margin-bottom: 20px;

	&--error {
		.form-group__input,
		.form-group__textarea {
			border-color: #e53a3a;
		}
	}
}

.form__group-title {
	position: absolute;
	left: 30px;
	top: 50%;
	font-size: 15px;
	font-weight: normal;
	color: #36617f;
	transform: translateY(-50%);
	transition: opacity .4s;
	pointer-events: none;

	span {
		font-size: 18px;
		color: #e53a3a;
	}
}

.form__bottom {
	max-width: 510px;
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.form__button {
	flex-shrink: 0;
}

.form__consent {
	font-size: 12px;
	line-height: 1;
	color: #9B9B9B;
	margin-left: 20px;
}


// FILE
// -----------------------------------------------
.file {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 270px;
	cursor: pointer;
	padding-left: 40px;
}

.file__input {
	@include visually-hidden;
}

.file__text {
	position: relative;
	display: inline-block;
	font-size: 17px;
	line-height: 21px;
	color: #004990;
	border-bottom: 1px solid #004990;

	&::before {
		content: '';
		position: absolute;
		top: -6px;
		left: -40px;
		width: 25px;
		height: 36px;
		background-image: url('/img/icons/file.png');
		@include background-img;
	}
}

.file__name {
	position: relative;
	display: none;
	font-size: 14px;
	line-height: 18px;
	color: #9B9B9B;
	margin-top: 25px;

	&::before {
		content: '';
		position: absolute;
		top: 1px;
		left: -25px;
		width: 16px;
		height: 16px;
		background-image: url('/img/icons/cross-circle.png');
		@include background-img;
	}

	span {
		color: #0999fc
	}
}
// -----------------------------------------------
// FILE
