// ===============================================
// FOOTER
// ===============================================


.footer {
	position: relative;
	color: #ffffff;
	background: linear-gradient(to right, #004990 30%, #0079d3), #004990;
	padding-top: 45px;
	border-bottom: 20px solid #00305f;
	box-shadow: inset 0px 40px 50px -20px #00305f;
	z-index: 1;

	@media (min-width: 992px) {
		padding-top: 60px;
	}

	@media (min-width: 1201px) {
		padding-top: 95px;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: 270px;
		background-color: rgb(91, 197, 242, .07);
		transform: skew(-10deg);
		z-index: -1;
	}

	&::before {
		left: -100px;
	}

	&::after {
		right: 19%;

		@media (max-width: 767px) {
			display: none;
		}
	}

	&--subpage {
		background: #004990;
		background-clip: padding-box;
		border-top: 1px solid rgba(255,255,255, .17);
		padding-top: 0;
		box-shadow: none;

		&::before,
		&::after {
			display: none;
		}

		.footer__inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 15px;

			@media (min-width: 768px) {
				padding-top: 45px;
				padding-bottom: 45px;
			}

			@media (max-width: 767px) {
				flex-direction: column;
			}
		}

		.footer__button {
			@media (min-width: 768px) {
				margin-top: 0;
			}
		}

		a {
			color: #5bc5f2;
		}
	}
}

.footer__inner {
	max-width: 1790px;
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1830px;
		padding: 0 40px;
	}

	&--mobile {
		@media (min-width: 992px) {
			display: none;
		}

		@media (max-width: 991px) {
			padding-bottom: 30px;
		}
	}
}

.footer__grid {
	max-width: 1430px;
	display: grid;
	justify-content: space-between;
	grid-template-columns: 1fr 1fr 1fr 270px;
	grid-gap: 30px 20px;
	padding: 0 40px 40px 40px;
	margin: 0 auto;

	@media (max-width: 991px) {
		display: none;
	}
}

.footer__block {
	max-width: 270px;
	width: 100%;
	align-self: flex-start;
}

.footer__title {
	display: block;
	font-size: 14px;
	line-height: 18px;
	text-transform: uppercase;
	color: #ffffff;
	font-family: 'Helvetica-6';
	text-decoration: none;

	@media (hover: hover) {
		&:hover,
		&:focus {
			color: #72cdf4;
		}
	}

	& + .footer__title {
		margin-top: 50px;
	}
}

.footer__menu {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: 20px;

	@media (max-width: 991px) {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0 -15px;
	}
}

.footer__menu-item {
	@media (min-width: 992px) {
		margin-top: 15px;
	}

	@media (max-width: 991px) {
		margin: 0 45px 15px 15px;
	}

	@media (max-width: 600px) {
		margin-right: 15px;
	}
}

.footer__menu-link {
	display: inline-block;
	font-size: 13px;
	line-height: 15px;
	font-weight: normal;
	color: #ffffff;
	text-decoration: none;

	@media (hover: hover) {
		&:hover,
		&:focus {
			color: #72cdf4;
		}
	}

	@media (max-width: 991px) {
		font-size: 14px;
		line-height: 18px;
		color: #ffffff;
		font-family: 'Helvetica-6';
		text-transform: uppercase;
	}
}

.footer__contacts {
	@media (min-width: 992px) {
		grid-row: 1/3;
		grid-column: 4/5;
	}
}

.footer__email {
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 1;
	color: #5bc5f2;
	border-bottom: 1px solid #5bc5f2;
	text-decoration: none;
	transition: border-color .4s;
	padding-bottom: 5px;
	margin-left: 30px;
	margin-top: 25px;

	&::before {
		content: '';
		position: absolute;
		top: -1px;
		left: -30px;
		width: 30px;
		height: 21px;
		background-image: url('/img/icons/email-light.png');
		background-position: left center;
		background-size: 21px 21px;
		background-repeat: no-repeat;
		padding-right: 9px;
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			border-color: transparent;
		}
	}
}

.footer__phone {
	margin-top: 25px;
}

.footer__phone-title {
	font-size: 12px;
	line-height: 14px;
	font-weight: normal;
}

.footer__phone-number {
	display: inline-block;
	font-family: 'Helvetica-6';
	font-size: 21px;
	line-height: 25px;
	color: #ffffff;
	margin-top: 10px;
}

.footer__button {
	margin-top: 30px;
}

.footer__bottom {
	font-size: 12px;
	line-height: 14px;
	font-weight: normal;
	background-color: #004990;
	border-top: 1px solid rgba(255,255,255, .17);
	padding-top: 30px;
	padding-bottom: 25px;

	@media (max-width: 767px) {
		text-align: center;
		padding-top: 20px;
	}

	.container {
		padding: 0 15px;

		@media (min-width: 768px) {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			padding: 0 20px;
		}

		@media (min-width: 768px) and (max-width: 991px) {
			flex-wrap: wrap;
		}

		@media (min-width: 992px) {
			max-width: 1470px;
			width: 100%;
			padding: 0 40px;
			margin: 0 auto;
		}
	}
}

.footer__copyright {
	@media (min-width: 992px) {
		order: -1;
	}

	@media (max-width: 991px) {
		margin-top: 25px;
	}
}

.footer__socials {
	@media (max-width: 992px) {
		justify-content: center;
		margin-top: 25px;
		margin-left: -25px;
	}
}

.footer__center {
	@media (min-width: 768px) {
		text-align: center;
	}

	@media (min-width: 992px) {
		margin-left: -150px;
	}

	@media (min-width: 768px) and (max-width: 991px) {
		width: 100%;
	}
}

.footer__logo {
	width: 150px;
	margin: 0 auto 10px auto;

	@media (min-width: 768px) {
		width: 230px;
		margin-bottom: 15px;
	}
}

.footer__personal-data {
	color: #5bc5f2;
}
