// ===============================================
// FEATURES
// ===============================================


.features {
	position: relative;
	overflow: hidden;
	color: #ffffff;
	background: linear-gradient(to right, #004990 30%, #0079d3),
			#004990;
	padding-top: 35px;
	padding-bottom: 35px;
	z-index: 1;

	@media (min-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-top: 80px;
		padding-bottom: 90px;
	}

	@media (min-width: 1440px) {
		padding-top: 170px;
		padding-bottom: 160px;
	}

	&::before {
		content: url('/img/logo-transparent.svg');
		position: absolute;
		top: 10px;
		left: 50%;
		max-width: 1091px;
		width: 100%;
		height: auto;
		opacity: .05;
		pointer-events: none;
		transform: translateX(-50%);
		z-index: -1;

		@media (min-width: 1440px) {
			left: 40%;
		}
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: -15%;
		width: 84%;
		bottom: 0;
		background-color: rgba(91,197,242, .07);
		transform: skew(-25deg);
		transition: transform .4s;
		z-index: -1;
	}
}

.features__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1710px;
		padding: 0 40px;
	}
}

.features__heading {
	@media (max-width: 767px) {
		text-align: center;
	}
}

.features__equipment {
	display: block;
	font-size: 12px;
	line-height: 14px;
	color: #5bb5ff;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.features__title {
	color: #ffffff;
	text-transform: uppercase;
}

.features__list {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 20px;
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: 30px;

	@media (min-width: 768px) {
		grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	}

	@media (min-width: 992px) {
		margin-top: 40px;
	}

	@media (min-width: 1440px) {
		margin-top: 50px;
	}
}

.features__item {
	max-width: 360px;
	justify-self: center;
	width: 100%;
	font-size: 18px;
	line-height: 1;
	font-weight: normal;
	background-color: rgba(0,67,133, .8);
	padding: 50px 25px;

	@media (min-width: 768px) {
		min-height: 205px;
		padding: 75px 30px 60px 30px;
	}
}

.features__item-number {
	font-size: 16px;
	line-height: 1;
	font-weight: 500;
	color: #5bb5ff;
	margin-top: -20px;

	@media (min-width: 768px) {
		margin-top: -40px;
	}

	span {
		font-size: 48px;
	}
}

.features__item-text {
	font-size: 15px;
	line-height: 18px;
	font-weight: 500;
	margin-top: 15px;
}
