// ===============================================
// COMMON PROJECT STYLES
// ===============================================


html,
body {
	height: 100%;
}

body {
	overflow-x: hidden;
	background-color: #f9f9f9;
	color: #0c2844;
	font-family: 'Gotham Pro';
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	text-rendering: optimizeLegibility;

	@media (min-width: 992px) {
		line-height: 24px;
	}
}

.fon-line {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
	z-index: -1;

	@media (min-width: 992px) {
		max-height: 2000px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: -10%;
		width: 460px;
		height: 100%;
		background-clip: padding-box;
		background-color: rgb(91, 197, 242, .11);
		border-right: 230px solid rgb(91, 197, 242, .05);
		transform: skew(-10deg);
		z-index: -1;

		@media (min-width: 992px) {
			left: 4%;
		}
	}

	@media (min-width: 992px) {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			height: 100%;
			right: 2%;
			width: 230px;
			background-color: rgb(91, 197, 242, .11);
			transform: skew(-10deg);
			z-index: -1;
		}
	}
}

.root {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
	background-color: #f9f9f9;
	z-index: 1;

	@media (max-width: 991px) {
		overflow: hidden;
	}
}

main {
	flex-grow: 1;
	flex-shrink: 0;
	margin-top: 120px;

	@media (min-width: 1440px) {
		margin-top: 170px;
	}
}
