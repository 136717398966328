// ===============================================
// MENU
// ===============================================


.menu__list {
	position: relative;
	width: 100%;
	list-style: none;
	padding: 0;
	margin: 0;

	&--sub {
		@media (min-width: 992px) {
			position: absolute;
			left: 545px;
			top: 10px;
			max-width: 300px;
			opacity: 0;
			transform: translateY(30px);
			visibility: hidden;
			transition: all 0s ease;
			display: block !important;
		}

		.menu__item {
			& + .menu__item {
				margin-top: 20px;
			}
		}

		.menu__link {
			font-size: 14px;
			line-height: 1;
			font-weight: normal;

			&::before {
				display: none;
			}

			@media (min-width: 768px) {
				font-size: 18px;
				line-height: 1;
			}
		}

		@media (max-width: 991px) {
			display: none;
			margin-top: 25px;
		}
	}

	&--open {
		display: block;
	}
}

.menu__item {
	& + .menu__item {
		margin-top: 35px;
	}

	@media (max-width: 991px) {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		&--open {
			.menu__link {
				&::before {
					opacity: 1;
				}
			}

			.menu__button {
				transform: rotate(-180deg);
			}
		}

		& > .menu__link {
			order: -1;
		}
	}
}

.menu__link {
	position: relative;
	display: inline-block;
	font-size: 25px;
	line-height: 1;
	color: #ffffff;
	text-decoration: none;

	@media (min-width: 768px) {
		font-size: 36px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 10px;
		left: -30px;
		width: 20px;
		height: 3px;
		transform: skew(-10deg);
		background-color: #5bc5f2;
		opacity: 0;
		transition: opacity .4s;

		@media (min-width: 768px) {
			top: 17px;
			left: -65px;
			width: 45px;
			height: 4px;
		}
	}

	@media (min-width: 992px) {
		&--active {
			&::before {
				opacity: 1;
			}

			& + .menu__list--sub {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition-delay: 0.2s;
				transition: all .5s;
			}
		}
	}
}

.menu__button {
	flex-shrink: 0;
	width: 20px;
	height: 20px;
	background-image: url('/img/icons/arrow.png');
	background-size: 12px 8px;
	background-position: center;
	background-repeat: no-repeat;
	transition: transform .4s;
	margin-left: 25px;

	@media (min-width: 992px) {
		display: none;
	}
}
