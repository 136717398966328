// ===============================================
// IMAGE MIXINS
// ===============================================


/*
	Responsive images
	Keep images from scaling beyond the width of their parents.
*/

@mixin img-fluid {
	display: block;
	max-width: 100%;
	height: auto;
}

@mixin background-img {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}
