// ===============================================
// DIRECTIONS
// ===============================================


.directions {
	&--main {
		padding-top: 25px;
		padding-bottom: 80px;

		@media (min-width: 768px) {
			padding-top: 0;
			padding-bottom: 100px;
		}

		@media (min-width: 992px) {
			padding-bottom: 120px;
		}
	}

	&--index {
		position: relative;
		background-color: #f9f9f9;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			width: 230px;
			height: 100%;
			background-color: rgb(91, 197, 242, .11);
			transform: skew(-10deg);
			z-index: -1;
		}

		&::before {
			left: 40px;
		}

		&::after {
			right: 35px;
		}

		&::before {
			@media (max-width: 991px) {
				display: none;
			}
		}

		padding-top: 35px;
		padding-bottom: 35px;
		overflow: hidden;
		z-index: 1;

		@media (min-width: 768px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}

		@media (min-width: 992px) {
			padding-top: 80px;
			padding-bottom: 60px;
		}

		@media (min-width: 1440px) {
			padding-top: 130px;
		}

		.directions__inner {
			@media (min-width: 1281px) {
				max-width: 1590px;
				padding-left: 160px;
			}

			@media (min-width: 1701px) {
				max-width: 1710px;
				padding-right: 160px;
			}
		}
	}
}

.directions__inner {
	width: 100%;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1470px;
		padding: 0 40px;
	}
}

.directions__headline {
	position: relative;
	z-index: 2;

	@media (min-width: 1281px) {
		max-width: 1300px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.directions__description {
	@media (min-width: 1281px) {
		max-width: 640px;
		padding-top: 10px;
	}

	@media (min-width: 992px) {
		font-size: 15px;
	}

	@media (max-width: 1200px) {
		margin-top: 30px;
	}
}

.directions__list {
	list-style: none;
	padding: 0;
	margin: 0;
	margin-top: 40px;

	@media (min-width: 701px) {
		margin-top: 70px;
	}

	@media (min-width: 701px) {
		margin-top: 100px;
	}

	@media (min-width: 1201px) {
		display: flex;
		justify-content: space-between;
		list-style: none;
	}

	@media (min-width: 1440px) {
		margin-top: 135px;
	}

	@media (min-width: 1920px) {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			right: -45px;
			width: 230px;
			height: 1000px;
			background-color: rgb(91, 197, 242, .07);
			transform: skew(-10deg);
			z-index: -1;
		}
	}
}

.directions__item {
	max-width: 700px;

	@media (min-width: 1201px) {
		width: 47%;
	}

	@media (min-width: 601px) and (max-width: 1200px) {
		& + .directions__item {
			margin-top: 90px;
		}
	}

	@media (max-width: 600px) {
		&+.directions__item {
			margin-top: 50px;
		}
	}
}
