// ===============================================
// CITY
// ===============================================


.city {
	display: block;
	max-width: 650px;
	width: 100%;

	@media (max-width: 480px) {
		width: auto;
		margin: 0 -15px
	}
}

.city__title {
	position: relative;
	font-size: 30px;
	line-height: 1;
	color: #ffffff;
	margin-bottom: 40px;

	@media (min-width: 768px) {
		font-size: 36px;
		line-height: 1;
		margin-bottom: 80px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 17px;
		left: -65px;
		width: 45px;
		height: 4px;
		transform: skew(-10deg);
		background-color: #5bc5f2;
	}
}

.city__list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 15px 20px;
	justify-content: space-between;
	list-style: none;
	width: 100%;
	padding: 0;
	margin: 0;

	@media (min-width: 768px) {
		grid-template-columns: repeat(2, 200px);
	}
}

.city__item {
	max-width: 200px;
}

.city__link {
	font-size: 18px;
	line-height: 1;
	font-weight: normal;
	color: #ffffff;

	@media (hover: hover) {
		&:hover,
		&:focus {
			color: #5bc5f2;
		}
	}
}
