// ===============================================
// TEXT HELPERS
// ===============================================


// stylelint-disable block-opening-brace-newline-after, block-closing-brace-newline-before, block-closing-brace-space-before, block-opening-brace-space-before, rule-empty-line-before


// ALIGNMENT
// -----------------------------------------------

.font-italic {
	font-style: italic;
}

.td-u {
	text-decoration: underline;
}

.td-n {
	text-decoration: none;
}

.tt-l {
	text-transform: lowercase;
}

.tt-u {
	text-transform: uppercase;
}

.ta-j {
	text-align: justify;
}

.ta-l {
	text-align: left;
}

.ta-r {
	text-align: right;
}

.ta-c {
	text-align: center;
}

// -----------------------------------------------
// ALIGNMENT


// stylelint-enable block-opening-brace-newline-after, block-closing-brace-newline-before, block-closing-brace-space-before, block-opening-brace-space-before, rule-empty-line-before
