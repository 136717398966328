// ===============================================
// SLIDER
// ===============================================


.slider {
	position: relative;
	max-width: 900px;
	width: 100%;
	overflow: visible;
	margin: 0;

	@media (min-width: 992px) {
		&::before {
			content: '';
			position: absolute;
			top: -55px;
			bottom: -75px;
			right: 80px;
			width: 230px;
			background-color: rgb(91, 197, 242, .14);
			transform: skew(-10deg);
			z-index: -1;
		}
	}

	.slider-button {
		position: absolute;
		top: 50%;
		right: 25px;
		transform: skew(-10deg) translateY(-50%);
		z-index: 1;

		@media (max-width: 600px) {
			display: none;
		}
	}
}

.slider__item {
	position: relative;
	display: block;
	width: 100%;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: -6%;
		left: -6%;
		background-color: rgb(0, 73, 144, .11);
		border-right: 100px solid rgb(91, 197, 242, .28);
		transform: skew(-10deg);
		z-index: 1;

		@media (min-width: 768px) {
			border-right-width: 140px;
		}
	}

	&--video {
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 74px;
			height: 74px;
			background-color: #ffffff;
			background-image: url('/img/icons/play.png');
			background-size: 15px 19px;
			background-position: left 31px center;
			background-repeat: no-repeat;
			border-radius: 50%;
			transform: translate(-50%, -50%);
			box-shadow: 5px 18px 25px 0 rgba(0, 73, 144, .8), 0 0 0 16px rgba(255, 255, 255, .33), -10px -10px 15px -10px rgba(91, 197, 242, .2);
			z-index: 1;
		}

		@media (hover: hover) {
			&:hover,
			&:focus {
				&::after {
					animation: pulse 1.5s infinite;
				}
			}
		}
	}
}

@keyframes pulse {
	0% {
		box-shadow: 5px 18px 25px 0 rgba(0, 73, 144, .8),
		0 0 0 0 rgba(255, 255, 255, .8),
		-10px -10px 15px -10px rgba(91, 197, 242, .2);
	}

	80% {
		box-shadow: 5px 18px 25px 0 rgba(0, 73, 144, .8),
		0 0 0 25px rgba(255, 255, 255, 0),
		-10px -10px 15px -10px rgba(91, 197, 242, .2);
	}

	100% {
		box-shadow: 5px 18px 25px 0 rgba(0, 73, 144, .8),
		0 0 0 0 rgba(255, 255, 255, 0),
		-10px -10px 15px -10px rgba(91, 197, 242, .2);
	}
}

.slider__img {
	display: block;
	width: 100%;
	height: auto;
}
