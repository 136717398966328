// ===============================================
// HIDE CONTENT VISUALLY
// ===============================================


/*
	Hide content visually while keeping it accessible to assistive technologies

	See: https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
	See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
*/

@mixin visually-hidden() {
	position: absolute;
	width: 1px;
	height: 1px;
	// Fix for https://github.com/twbs/bootstrap/issues/25686
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
}


/*
	Use to only display content when it's focused.

	Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
*/

@mixin visually-hidden-focusable() {
	&:not(:focus) {
		@include visually-hidden;
	}
}
