// ===============================================
// SUBPAGE
// ===============================================


.subpage {
	position: relative;
	display: flex;
	flex-direction: column;
	@include style-cover-fixed;
	background: linear-gradient(to right, #004990 30%, #0079d3),
		#004990;
	overflow-y: auto;
	transform: translateY(-105%);
	transition: transform 1s cubic-bezier(.77, 0, .175, 1);
	z-index: 110;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: -170px;
		width: 340px;
		background-clip: padding-box;
		background-color: rgb(91, 197, 242, .14);
		transform: skew(-10deg);
		z-index: -1;
	}

	&-scroll {
		overflow: hidden;
	}

	&--open {
		transform: translateY(0);
	}

	@media (max-width: 1280px) {
		.footer {
			border-bottom-width: 80px;
		}
	}
}

.subpage__inner {
	display: flex;
	align-items: center;
	max-width: 980px;
	width: 100%;
	flex-grow: 1;
	flex-shrink: 0;
	padding: 30px 35px 50px 35px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 40px 65px 80px 65px;
	}
}
