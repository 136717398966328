// ===============================================
// OUR-CLIENTS
// ===============================================


.our-clients {
	overflow: hidden;

	@media (max-width: 767px) {
		padding-top: 25px;
	}
}

.our-clients__inner {
	width: 100%;
	padding: 0 15px 40px 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px 60px 15px;
	}

	@media (min-width: 768px) {
		max-width: 1710px;
		padding-left: 40px;
		padding-right: 40px;
	}
}

.our-clients__title {
	margin-bottom: 20px;

	@media (min-width: 768px) {
		margin-bottom: 30px;
	}
}

.our-clients__sliders {
	background-color: #ffffff;
	padding: 40px 0 80px 0;

	@media (min-width: 768px) {
		padding-top: 60px;
		padding-bottom: 100px;
	}

	@media (min-width: 992px) {
		padding-bottom: 120px;
	}

	@media (min-width: 1280px) {
		padding-bottom: 150px;
	}
}

.our-clients__slider {
	position: relative;
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;

	.slider-btn {
		position: absolute;
		top: 50%;
		margin-top: -20px;
		z-index: 1;

		@media (max-width: 991px) {
			display: none;
		}

		&--prev {
			left: 5px;

			@media (min-width: 1801px) {
				left: -75px;
			}
		}

		&--next {
			right: 5px;

			@media (min-width: 1801px) {
				right: -75px;
			}
		}
	}

	// &--reverse {
	// 	padding-left: 75px;

	// 	@media (min-width: 768px) {
	// 		padding-left: 25px;
	// 	}
	// }

	// & + .our-clients__slider {
	// 	margin-top: 20px;

	// 	@media (min-width: 768px) {
	// 		margin-top: 50px;
	// 	}
	// }

	// .swiper-wrapper {
	// 	-webkit-transition-timing-function: linear !important;
	// 	transition-timing-function: linear !important;
	// }
}

.our-clients__column {
	width: 120px;
	height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	@media (min-width: 768px) {
		width: 155px;
		height: 550px;
	}
}

.our-clients__item {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 88px;

	@media (min-width: 768px) {
		width: 155px;
		height: 110px;
	}
}

.our-clients__img {
	display: block;
	max-width: 100%;
	max-height: 100%;
	user-select: none;
}
