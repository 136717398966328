// ===============================================
// ARTILCE
// ===============================================


.article {
	@media (min-width: 992px) {
		font-size: 16px;
		line-height: 24px;
	}

	h2 {
		margin-top: 30px;
		margin-bottom: 20px;

		@media (min-width: 768px) {
			margin-top: 40px;
		}

		@media (min-width: 992px) {
			margin-top: 50px;
			margin-bottom: 35px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h3 {
		margin-top: 30px;
		margin-bottom: 20px;

		@media (min-width: 768px) {
			margin-top: 35px;
		}

		@media (min-width: 992px) {
			margin-top: 45px;
			margin-bottom: 35px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h4 {
		margin-top: 30px;
		margin-bottom: 20px;

		@media (min-width: 992px) {
			margin-top: 40px;
			margin-bottom: 30px;
		}

		&:first-child {
			margin-top: 0;
		}
	}
}

.marker-list {
	font-size: 14px;
	line-height: 20px;
	list-style: none;
	padding: 0;
	margin: 30px 0;
	@include style-m0;

	@media (min-width: 768px) {
		font-size: 16px;
		line-height: 21px;
	}

	@media (min-width: 1440px) {
		font-size: 18px;
	}

	li {
		position: relative;
		padding-left: 25px;
		margin-bottom: 20px;

		@media (min-width: 768px) {
			margin-bottom: 30px;
		}

		@media (min-width: 1440px) {
			margin-bottom: 50px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			content: '';
			position: absolute;
			top: 5px;
			left: -5px;
			width: 10px;
			height: 10px;
			background-color: #0999fc;
			border-radius: 50%;
		}
	}
}

.circle-list {
	font-size: 14px;
	line-height: 18px;
	list-style: none;
	padding: 0;
	margin: 30px 0;
	@include style-m0;

	li {
		position: relative;
		padding-left: 30px;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		&::before {
			content: '';
			position: absolute;
			top: 1px;
			left: 0;
			width: 16px;
			height: 16px;
			background-color: transparent;
			border: 4px solid #5bb5ff;
			border-radius: 50%;
		}
	}

	span {
		font-size: 24px;
		line-height: 14px;
	}

	@media (min-width: 601px) {
		&--two-columns {
			display: grid;
			justify-content: space-between;
			grid-template-columns: 1fr 1fr;
			grid-gap: 30px 10px;

			li {
				margin-bottom: 0;
			}
		}
	}
}


.column-list {
	display: grid;
	justify-content: space-between;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 20px;
	font-size: 14px;
	line-height: 20px;
	font-weight: normal;
	list-style: none;
	padding: 0;
	margin: 30px 0;
	@include style-m0;

	@media (min-width: 1201px) {
		grid-gap: 30px 10px;
		line-height: 24px;
		margin: 60px 0;
	}

	li {
		width: 100%;

		@media (min-width: 1201px) {
			min-width: 250px;
			max-width: 260px;

			p + p {
				margin-top: 25px;
			}
		}
	}

	& + .text__slider {
		margin-top: 20px;
	}
}
