// ===============================================
// TEXT
// ===============================================


.text {
	position: relative;
	background-color: #ffffff;
	padding-top: 35px;
	padding-bottom: 35px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 17%;
		width: 225px;
		background-color: rgba(91,197,242, .11);
		transform: skew(-10deg);
	}

	@media (min-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-top: 80px;
		padding-bottom: 90px;
	}

	@media (min-width: 1440px) {
		padding-top: 105px;
		padding-bottom: 130px;
	}
}

.text__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		max-width: 1710px;
		padding: 0 40px;
	}

	@media (min-width: 1440px) {
		padding-right: 140px;
	}
}

.text__title {
	font-size: 16px;
	line-height: 24px;
	color: #0c2844;
	text-transform: uppercase;
	margin-bottom: 20px;

	@media (min-width: 768px) {
		margin-bottom: 40px;
	}

	@media (min-width: 992px) {
		margin-bottom: 60px;
	}

	@media (min-width: 1440px) {
		margin-bottom: 75px;
	}
}

.text__content {
	@media (min-width: 1201px) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.text__text {
			max-width: 1135px;
		}
	}
}

.text__nav {
	position: sticky;
	top: 160px;
	left: 0;
	width: 295px;
	flex-shrink: 0;
	margin-right: 30px;

	@media (max-width: 1200px) {
		display: none;
	}
}

.text__nav-title {
	margin-bottom: 55px;
}

.text__nav-list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.text__nav-item {
	margin-top: 20px;
}

.text__nav-link {
	position: relative;
	display: inline-block;
	font-size: 18px;
	line-height: 21px;
	color: #1d2429;
	text-decoration: none;
	transition: color .4s;
	padding-left: 75px;

	&::before {
		content: '';
		position: absolute;
		top: 7px;
		left: 0;
		width: 45px;
		height: 4px;
		background-color: #5bc5f2;
		transform: skew(-10deg);
		opacity: 0;
		transition: opacity .4s;
	}

	&--current {
		color: #80a1b2;

		&::before {
			opacity: 1;
		}
	}

	@media (hover: hover) {

		&:hover,
		&:focus {
			color: #80a1b2;
		}
	}
}

.text__text {
	max-width: 1430px;
	width: 100%;

	@media (min-width: 992px) {
		font-size: 16px;
		line-height: 30px;
	}
}

.text__section {
	& + .text__section {
		margin-top: 50px;

		@media (min-width: 992px) {
			margin-top: 100px;
		}
	}

	&::after {
		content: '';
		display: block;
		clear: both;
	}

	@media (min-width: 992px) {
		p + p {
			margin-top: 30px;
		}
	}

	h2 {
		margin-top: 30px;
		margin-bottom: 20px;

		@media (min-width: 768px) {
			margin-top: 40px;
		}

		@media (min-width: 992px) {
			margin-top: 50px;
			margin-bottom: 35px;
		}

		@media (min-width: 1440px) {
			font-size: 40px;
			margin-bottom: 65px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h3 {
		margin-top: 30px;
		margin-bottom: 20px;

		@media (min-width: 768px) {
			margin-top: 35px;
		}

		@media (min-width: 992px) {
			margin-top: 45px;
			margin-bottom: 35px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h4 {
		margin-top: 30px;
		margin-bottom: 20px;

		@media (min-width: 992px) {
			margin-top: 40px;
			margin-bottom: 30px;
		}

		&:first-child {
			margin-top: 0;
		}
	}
}


.foto-slider {
	max-width: 550px;
	width: 100%;
	overflow: visible;
	margin: 0;

	&--vertical {
		max-width: 335px;

		@media (max-width: 600px) {
			width: 85%;

			.foto-slider__img {
				&::before {
					max-width: 230px;
					width: 70%;
				}
			}
		}
	}
}

.foto-slider__img {
	position: relative;
	width: 100%;

	&::before {
		content: '';
		position: absolute;
		top: -25px;
		bottom: -25px;
		left: 50%;
		width: 230px;
		background-color: rgba(91,197,242, .4);
		transform: translateX(-50%) skew(-10deg);
	}

	img {
		position: relative;
		display: block;
		width: 100%;
		height: auto;
		z-index: 1;
	}
}

.foto-slider__text {
	font-size: 14px;
	line-height: 16px;
	font-weight: normal;
	padding-right: 140px;
	margin-top: 40px;

	@media (min-width: 992px) {
		margin-top: 60px;
	}
}

.foto-slider__btns {
	position: absolute;
	bottom: 0;
	right: 5px;
	z-index: 1;
}

.text__slider {
	@media (min-width: 601px) {
		width: 50%;
		float: left;
		margin: 50px 30px 40px 0;

		& + p {
			margin-top: 45px;
		}
	}

	@media (min-width: 992px) {
		margin-right: 55px;
	}

	@media (min-width: 1201px) {
		margin-top: 80px;

		& + p {
			margin-top: 70px;
		}
	}

	@media (max-width: 600px) {
		margin: 40px auto 35px auto;
		@include style-m0;
	}
}
