// ===============================================
// DOCUMENT
// тень
// ===============================================


.document {
	position: relative;
	width: 160px;
}

.document__title {
	font-size: 13px;
	line-height: 15px;
	color: #1d2429;
	margin-top: 20px;
}

.document__link {
	color: #1d2429;
	text-decoration: none;

	&::before {
		content: '';
		@include style-cover;
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			color: #0999fc;
		}
	}
}

.document__img {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 160px;
	height: 220px;
	background-color: #ffffff;
	box-shadow: 20px 0 20px -10px rgba(182,194,207, .5);

	img {
		display: block;
		max-width: 140px;
		max-height: 200px;
		width: auto;
		height: auto;
	}
}
