// ===============================================
// ABOUT-INDEX
// ===============================================


.about-index {
	position: relative;
	background-color: #ffffff;
	padding-top: 35px;
	padding-bottom: 35px;
	overflow: hidden;
	z-index: 1;

	@media (min-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-top: 85px;
		padding-bottom: 100px;
	}

	@media (min-width: 1440px) {
		padding-bottom: 160px;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 45%;
		width: 230px;
		background-color: rgba(91, 197, 242, .1);
		transform: skew(-10deg) translateX(-55%);
		z-index: -1;
	}
}

.about-index__inner {
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 992px) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: 0 40px;
	}

	@media (min-width: 1281px) {
		max-width: 1570px;
		padding-left: 160px;
	}

	@media (min-width: 1440px) {
		padding-right: 60px;
	}
}

.about-index__text {
	color: #848b92;

	@media (min-width: 992px) {
		max-width: 700px;
		width: 100%;
		margin-right: 30px;
	}

	@media (max-width: 991px) {
		p {
			display: none;
		}
	}
}

.about-index__title {
	@media (min-width: 992px) {
		margin-bottom: 35px;
	}
}

.about-index__button {
	margin-top: 30px;

	@media (min-width: 992px) {
		margin-top: 50px;
	}
}

.about-index__media {
	position: relative;
	max-width: 555px;
	width: 100%;

	@media (min-width: 768px) {
		&::before {
			content: '';
			position: absolute;
			top: -20px;
			left: 50%;
			bottom: -30px;
			width: 230px;
			background-color: rgba(91, 197, 242, .4);
			transform: skew(-10deg) translateX(-50%);
		}
	}

	@media (min-width: 992px) {
		flex-shrink: 0;
		width: 50%;
	}

	@media (max-width: 991px) {
		margin: 40px auto 0 auto;
	}

	@media (max-width: 767px) {
		margin-top: 30px;
	}
}

.about-index__video {
	position: relative;
	display: block;
	width: 100%;
	z-index: 1;

	&::before {
		content: '';
		@include style-cover;
		background-color: rgba(0,73,144, .36);
	}

	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 74px;
		height: 74px;
		background-color: #ffffff;
		background-image: url('/img/icons/play.png');
		background-position: left 31px center;
		background-repeat: no-repeat;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 5px 18px 25px 0 rgba(0, 73, 144, .8), 0 0 0 16px rgba(255, 255, 255, .33), -10px -10px 15px -10px rgba(91, 197, 242, .2);
		z-index: 1;
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			&::after {
				animation: pulse 1.5s infinite;
			}
		}
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}
