// ===============================================
// HEADER
// ===============================================


.header {
	z-index: 10;

	@media (min-width: 768px) {
		padding: 30px 20px 30px 20px;

		&--active {
			background-color: #ffffff;
		}
	}

	@media (min-width: 992px) {
		padding-left: 30px;
		padding-right: 30px;
	}

	@media (min-width: 1440px) {
		padding-top: 55px;
		padding-bottom: 30px;
	}

	&--fixed {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		transition: background-color .4s;

		@media (max-width: 767px) {
			height: 115px;
			background-color: #004990;
			border-bottom: 50px solid #ffffff;
			box-shadow: 0 5px 0 0 #e8f3f8;
			padding: 10px 5px 0px 5px;
		}
	}

	&--subpage {
		@media (max-width: 767px) {
			padding: 20px 15px 0 15px;
		}
	}
}

.header__inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1750px;
	width: 100%;
	margin: 0 auto;
}

.header__logo {
	flex-shrink: 0;

	@media (min-width: 768px) {
		margin-right: 25px;
	}

	@media (min-width: 1551px) {
		margin-right: 50px;
	}

	@media (max-width: 480px) {
		margin-left: -10px;
	}
}

.header__menu {
	max-width: 910px;
	width: 100%;
	margin-left: auto;

	@media (max-width: 1439px) {
		display: none;
	}
}

.header__menu-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	padding: 0;
	margin: 0;
}

.header__menu-item {

}

.header__menu-link {
	display: inline-block;
	font-family: 'Helvetica-6';
	font-size: 15px;
	line-height: 17px;
	text-transform: uppercase;
	color: #1d2429;
	text-decoration: none;

	@media (min-width: 1601px) {
		font-size: 17px;
		line-height: 19px;
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			color: #0999fc;
		}
	}
}

.header__contacts {
	flex-shrink: 0;
	text-align: right;
	margin-left: auto;

	@media (min-width: 1440px) {
		margin-left: 25px;
	}

	@media (min-width: 1551px) {
		margin-left: 50px;
	}

	@media (max-width: 767px) {
		position: absolute;
		right: 15px;
		bottom: -37px;
	}
}

.header__phone {
	display: inline-block;
	font-size: 24px;
	line-height: 1;
	font-family: 'Helvetica-6';
	color: #1a1b29;

	@media (hover: hover) {
		&:hover,
		&:focus {
			color: #0999fc;
		}
	}

	@media (max-width: 767px) {
		font-size: 18px;
	}
}

.header__email {
	position: relative;
	display: inline-block;
	font-size: 14px;
	line-height: 18px;
	color: #004990;
	text-decoration: none;
	border-bottom: 1px solid #004990;
	transition: border-color .4s;
	margin-top: 2px;

	&::before {
		content: '';
		position: absolute;
		top: 2px;
		left: -20px;
		width: 25px;
		height: 16px;
		padding-right: 9px;
		background-image: url('/img/icons/email-light.png');
		background-position: left center;
		background-size: 16px 16px;
		background-repeat: no-repeat;
	}

	@media (hover: hover) {

		&:hover,
		&:focus {
			border-color: transparent;
		}
	}

	@media (max-width: 767px) {
		display: none;
	}
}

.header__city {
	position: relative;
	font-size: 16px;
	line-height: 18px;
	font-weight: 500;
	color: #0999fc;
	border-bottom: 2px dotted #0999fc;
	transition: border-color .4s;
	margin-top: 5px;
	margin-left: 30px;

	&::before {
		content: '';
		position: absolute;
		top: 2px;
		left: -25px;
		width: 25px;
		height: 16px;
		padding-right: 9px;
		background-image: url('/img/icons/arrow-circle.png');
		background-position: left center;
		background-size: 16px 16px;
		background-repeat: no-repeat;
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			border-color: transparent;
		}
	}

	@media (max-width: 767px) {
		display: none;
	}
}

.header__btn {
	flex-shrink: 0;
	margin-left: 25px;
}

.header__button {
	margin-left: auto;

	@media (max-width: 374px), (min-width: 768px) {
		display: none;
	}

	@media (max-width: 480px) {
		width: 140px;
		padding: 10px;
	}
}

.header__underlogo {
	max-width: 100px;
	position: absolute;
	left: 15px;
	bottom: -37px;
	font-size: 12px;
	line-height: 1;
	color: #1a1b29;
	font-style: italic;

	@media (min-width: 768px) {
		display: none;
	}
}
