// ===============================================
// EQUIPMENT
// ===============================================


.equipment {
	position: relative;
	max-width: 570px;
	width: 100%;
	overflow: hidden;

	@media (hover: hover) {
		&::before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			width: 270px;
			left: 35%;
			background-color: rgb(91, 197, 242, .2);
			transform: skew(-10deg) translateX(450px);
			transition: transform .4s;
		}

		.equipment__button {
			&::before {
				opacity: 0;
				transition: all .4s;
			}

			&::after {
				opacity: 0;
				transition: opacity .4s;
			}
		}

		&:hover,
		&:focus-within {
			&::before {
				transform: skew(-10deg) translateX(0);
			}

			.equipment__button {
				&::before {
					opacity: 1;
				}

				&::after {
					opacity: .34;
				}
			}
		}
	}

	@media (max-width: 480px) {
		height: 225px;
	}
}

.equipment__img {
	display: block;
	width: 100%;
	height: auto;
	object-fit: cover;
	object-position: right bottom;

	@media (max-width: 480px) {
		position: absolute;
		bottom: 0;
		right: -80px;
		width: calc(100% + 80px);
		height: 100%;
		object-position: right top;
	}
}

.equipment__text {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	padding: 35px 0 25px 25px;

	@media (min-width: 481px) {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10% 0 8% 10%;
	}

	@media (max-width: 374px) {
		padding-top: 25px;
		padding-left: 15px;
	}
}

.equipment__title {
	margin-bottom: 15px;

	@media (min-width: 481px) {
		font-size: 26px;
		line-height: 31px;
		margin-bottom: 7%;
	}
}

.equipment__link {
	color: #1d2429;
	text-decoration: none;

	&::before {
		content: '';
		@include style-cover;
		z-index: 2;
	}
}

.equipment__description {
	font-size: 14px;
	line-height: 18px;
	color: #848b92;
	margin-bottom: 10px;

	@media (min-width: 481px) {
		margin-bottom: 20px;
	}
}

.equipment__button {
	margin-top: auto;
}
