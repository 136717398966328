// ===============================================
// POSITION
// ===============================================


@mixin style-cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin style-cover-fixed {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin scroll-mobile {
	@media (max-width: 991px) {
		overflow: hidden;
		overflow-x: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		margin-left: -20px;
		margin-right: -20px;
		padding-left: 20px;

		&::-webkit-scrollbar {
			height: 0px;
			background: transparent;
		}
	}

	@media (max-width: 767px) {
		margin-left: -15px;
		margin-right: -15px;
		padding-left: 15px;
	}
}

@mixin style-m0 {
	&:last-child {
		margin-bottom: 0;
	}
	&:first-child {
		margin-top: 0;
	}
}
