// ===============================================
// CLIENTS
// ===============================================


.clients {
     position: relative;
     background: linear-gradient(to right, #004990 30%, #0079d3),
               #004990;
     padding-top: 35px;
     padding-bottom: 35px;
     z-index: 1;

     &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 25%;
          bottom: 0;
          width: 225px;
          background-color: rgba(91,197,242, .07);
          transform: skew(-10deg);
     }

     @media (min-width: 768px) {
          padding-top: 50px;
          padding-bottom: 50px;
     }

     @media (min-width: 992px) {
          overflow: hidden;
          padding-top: 80px;
          padding-bottom: 90px;
     }

     @media (min-width: 1440px) {
          padding-top: 90px;
          padding-bottom: 130px;
     }
}

.clients__inner {
     width: 100%;
     padding: 0 15px;
     margin: 0 auto;

     @media (min-width: 768px) {
          padding: 0 20px;
     }

     @media (min-width: 992px) {
          max-width: 1425px;
          padding: 0 40px;
     }
}

.clients__title {
     color: #ffffff;
     margin-bottom: 30px;

     @media (min-width: 1440px) {
          padding-left: 130px;
          margin-bottom: 50px;
     }
}

.clients__slider {
     position: relative;

     .swiper {
          padding-top: 130px;
          margin-top: -130px;

          @media (min-width: 992px) and (max-width: 1439px) {
               padding-left: 20px;
               margin-left: -40px;
               margin-right: -40px;
          }

          @media (max-width: 991px) {
               padding-left: 20px;
               margin-left: -20px;
               margin-right: -20px;
          }
     }

     .slider-btn {
          position: absolute;
          top: 68%;
          z-index: 1;

          @media (max-width: 991px) {
               display: none;
          }

          &--prev {
               @media (min-width: 992px) {
                    left: -30px;
               }

               @media (min-width: 1601px) {
                    left: -100px;
               }
          }

          &--next {
               @media (min-width: 992px) {
                    right: -30px;
               }

               @media (min-width: 1601px) {
                    right: -100px;
               }
          }
     }

     //overflow: visible;

     // &:nth-child(2) {
     //      margin-left: 30px;

     //      @media (min-width: 1440px) {
     //           margin-left: 100px;
     //      }
     // }

     // & + .clients__slider {
     //      margin-top: 50px;
     // }
}

.clients__item {
     width: 130px;
     height: 220px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     //opacity: .35;
     //transition: opacity .4s;
     //pointer-events: none;

     // &.swiper-slide-visible {
     //      opacity: 1;
     //      pointer-events: auto;
     // }
}

.client {
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     user-select: none;
     width: 130px;
     height: 50px;

     img {
          display: block;
          max-width: 100px;
          max-height: 50px;
     }

     &--current {
          .client__information {
               opacity: 1;
               visibility: visible;
          }
     }

     @media (hover: hover) {
          &--current {
               .client__information {
                    opacity: 0;
                    visibility: hidden;
               }
          }

          &:hover,
          &:focus {
               .client__information {
                    opacity: 1;
                    visibility: visible;
               }
          }
     }
}

.client__information {
     position: absolute;
     bottom: 60px;
     left: 50%;
     width: 210px;
     text-align: center;
     background-color: #ffffff;
     border-radius: 10px;
     transform: translateX(-50%);
     padding: 15px;
     box-shadow: 0 20px 35px -16px #001324;
     opacity: 0;
     visibility: hidden;
     transition: all .4s;
     z-index: 1;

     &::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 12px solid #ffffff;
          transform: translateX(-50%);
     }
}

.client__information-img {
     display: flex;
     align-items: center;
     justify-content: center;
     width: 100px;
     height: 40px;
     margin: 0 auto 10px auto;
}

.client__information-name {
     font-size: 14px;
     line-height: 18px;
     font-weight: normal;
     color: #182839;
}
