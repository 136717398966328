// ===============================================
// STRUCTURE
// ===============================================


.structure {
	position: relative;
	color: #ffffff;
	background: linear-gradient(to right, #004990 30%, #0079d3),
	#004990;
	padding-top: 35px;
	padding-bottom: 35px;
	overflow: hidden;
	z-index: 1;

	@media (min-width: 768px) {
		padding-top: 50px;
		padding-bottom: 50px;
	}

	@media (min-width: 992px) {
		padding-top: 75px;
		padding-bottom: 125px;
	}

	&::before {
		content: url('/img/logo-transparent.svg');
		position: absolute;
		top: 0;
		left: 50%;
		max-width: 1092px;
		width: 100%;
		height: auto;
		opacity: .07;
		transform: translateX(-50%);
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 45%;
		width: 460px;
		background-color: rgb(91, 197, 242, .07);
		transform: skew(-10deg);
		z-index: -1;
	}
}

.structure__inner {
	max-width: 1020px;
	width: 100%;
	padding: 0 15px;
	margin: 0 auto;

	@media (min-width: 768px) {
		padding: 0 20px;
	}

	@media (min-width: 801px) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}

.structure__block {
	max-width: 420px;

	@media (min-width: 801px) {
		width: 49%;
	}

	@media (max-width: 800px) {
		margin: 0 auto;

		& + .structure__block {
			margin-top: 50px;
		}
	}
}

.structure__title {
	color: #ffffff;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.structure__list {
	position: relative;
	border-left: 2px dotted #5bb5ff;
	list-style: none;
	padding: 35px 0 0 30px;
	margin: 0;
	margin-left: 10px;

	@media (min-width: 801px) {
		padding-left: 40px;
		margin-left: 30px;
	}

	@media (max-width: 374px) {
		padding-left: 20px;
	}
}

.structure__information,
.structure__item {
	position: relative;

	&::before {
		content: '';
		position: absolute;
		left: -39px;
		width: 16px;
		height: 16px;
		background-color: transparent;
		border: 4px solid #5bb5ff;
		border-radius: 50%;

		@media (min-width: 801px) {
			left: -49px;
		}

		@media (max-width: 374px) {
			left: -29px;
		}
	}
}

.structure__information {
	position: relative;
	margin-bottom: 35px;

	@media (min-width: 801px) {
		margin-bottom: 55px;
	}

	&::before {
		top: 0;
	}
}

.structure__square {
	font-size: 16px;
	line-height: 18px;
	color: #5bb5ff;

	span {
		font-size: 48px;
	}
}

.structure__location {
	font-size: 15px;
	line-height: 18px;
	margin-top: 10px;
}

.structure__item {
	max-width: 310px;
	width: 100%;
	font-family: 'Helvetica-6';
	font-size: 14px;
	line-height: 17px;
	text-transform: uppercase;
	background-color: #004385;
	padding: 35px 30px 45px 35px;
	margin-top: 30px;

	@media (max-width: 374px) {
		padding: 20px 15px;
	}

	&::before {
		top: 30px;

		@media (max-width: 374px) {
			top: 20px;
		}
	}
}

.structure__item-description {
	font-family: 'Gotham Pro';
	font-size: 13px;
	line-height: 18px;
	font-weight: normal;
	text-transform: none;
	margin-top: 20px;
}
